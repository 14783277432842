import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import uniqBy from 'lodash/uniqBy'
import { fontBold, fontHeavy, fontLight, fontRegular } from 'fonts'

import { DISPATCH_SECTION_STATE } from 'shared/session/actionTypes'
import {
  CardDeckSection as CardDeckInterface,
  EmbeddedActivitySection,
  SectionPanel,
  SessionProfile,
  TurnStatus,
  TurnTakingSection,
  TurnTakingSectionState,
  Card as CardType,
} from 'shared/session/types'
import {
  ActionTypes,
  SelectCardAction,
  FlipCardAction,
  NextRoundAction,
  SELECT_CARD,
  FLIP_CARD,
  NEXT_ROUND,
} from 'shared/session/sections/custom/secret-transmission/actionTypes'

import { Button, Panel, Row, Spacer } from 'common/ui'
import { DisabledOverlay } from 'session/common/DisabledOverlay'
import { ConfirmModal } from 'common/ConfirmModal'
import { SVG } from 'common/SVG'
import { TurnTakingSectionFacilitator, getInitialSectionState as getInitialTurnTakingState } from '../TurnTaking'
import { CardFlip } from 'common/CardFlip'
import { ActivityInteractionIndicator } from 'session/common/ActivityInteractionIndicator'
import {
  CardDeckSection,
  CardDeckSectionFacilitator,
  getCards,
  getInitialSectionState as getInitialCardDeckState,
} from '../CardDeck'

import { useSessionState } from 'session/SessionState'
import { getImageUrl } from 'session/utils/assetUtils'
import { useFocusedParticipantState } from 'session/hooks/useProfileState'
import { useSectionStateWithFallback } from 'session/hooks/useSectionState'

const gif = require('../../assets/wave-length.gif')
const staticImg = require('../../assets/wave-length.png')
const fingerGif = require('../../assets/finger-scan.gif')
const fingerStaticImg = require('../../assets/finger-scan.png')

const containerHeights: { [key in Exclude<TurnTakingSection['size'], null>]: number } = {
  large: 600,
  medium: 480,
  small: 320,
}

const getInitialSelectionState = () => ({})

interface Props {
  section: EmbeddedActivitySection
  panel: SectionPanel
  index: number
}

export const SecretTransmission: React.FC<Props> = ({ section, panel, index }) => {
  const { transmission_turn_taking_section, transmission_card_deck_section } = section
  if (!transmission_turn_taking_section || !transmission_card_deck_section) throw new Error('Missing Section!')

  const participantState = useFocusedParticipantState()

  const turnTakingSectionState = useSectionStateWithFallback(
    'turn_taking_sections',
    transmission_turn_taking_section,
    getInitialTurnTakingState
  )
  const activeProfileUid =
    participantState &&
    participantState.profile.uid in turnTakingSectionState &&
    (turnTakingSectionState[participantState.profile.uid] === 'active' ||
      turnTakingSectionState[participantState.profile.uid] === 'prepare')
      ? participantState.profile.uid
      : undefined

  return (
    <>
      <TurnDisplay
        section={section}
        sectionState={turnTakingSectionState}
        profile={participantState ? participantState.profile : null}
        panel={panel}
      />
      <Spacer size="m" />
      {activeProfileUid && (
        <CardDeckSection
          activeProfileId={activeProfileUid}
          property="card_deck_sections"
          index={index}
          panel={panel}
          section={transmission_card_deck_section}
        />
      )}
    </>
  )
}

interface SectionProps {
  section: Props['section']
  sectionState: TurnTakingSectionState
  profile: SessionProfile | null
  panel: SectionPanel
}

const getTurnLabels = (section: TurnTakingSection): { [key in TurnStatus]: string } => ({
  wait: section.wait_text || '',
  prepare: section.prepare_text || '',
  active: section.active_text || '',
})

const TurnDisplay: React.FC<SectionProps> = ({ section, sectionState, profile }) => {
  const { transmission_turn_taking_section, transmission_card_deck_section } = section
  const turnKey: TurnStatus = profile && profile.uid in sectionState ? sectionState[profile.uid] : 'wait'
  const [playGif, setPlayGif] = useState<boolean>(false)
  const [bgImage, setBgImage] = useState<string>(staticImg)
  const [fingerImage, setFingerImage] = useState<string>(fingerStaticImg)
  const measureContainer = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (playGif) {
      setFingerImage(fingerGif)
      setBgImage(gif)
    } else {
      setFingerImage(fingerStaticImg)
      setBgImage(staticImg)
    }
  }, [playGif])

  if (!transmission_turn_taking_section || !transmission_card_deck_section) {
    return null
  }

  const turnLabels = getTurnLabels(transmission_turn_taking_section)

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault()
    setPlayGif(true)
  }

  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault()
    setPlayGif(false)
  }

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (measureContainer.current) {
      const container = measureContainer.current.getBoundingClientRect()
      const touch = e.touches[0]
      const withinX = container.left < touch.clientX && container.right > touch.clientX
      const withinY = container.top < touch.clientY && container.bottom > touch.clientY

      if (!withinX || !withinY) handleMouseUp(e)
    }
  }

  return (
    <>
      {turnKey === 'wait' && (
        <GuessingSection
          label={turnLabels[turnKey]}
          section={section}
          sectionState={sectionState}
          cardSection={transmission_card_deck_section}
        />
      )}
      {turnKey === 'prepare' && (
        <Wrapper>
          <TurnWrapper>
            <TurnSection
              containerHeight={transmission_turn_taking_section.size}
              backgroundImage={require('../../assets/shhh.png')}>
              <LabelBox children={turnLabels[turnKey]} />
            </TurnSection>
            <TurnSectionRight>
              <Panel className="static" theme="white">
                <img src={fingerStaticImg} alt="" />
                <p>Press to transmit</p>
              </Panel>
            </TurnSectionRight>
          </TurnWrapper>
        </Wrapper>
      )}
      {turnKey === 'active' && (
        <Wrapper>
          <TurnWrapper>
            <TurnSection
              containerHeight={transmission_turn_taking_section.size}
              backgroundImage={bgImage}
              backgroundColor="#102032"
              imageSize="cover">
              <LabelBox children={turnLabels[turnKey]} style={{ opacity: playGif ? 0 : 1, transition: '0.5s' }} />
            </TurnSection>
            <TurnSectionRight backgroundColor="#102032">
              <Panel
                ref={measureContainer}
                theme="white"
                onTouchStart={(e) => handleMouseDown(e)}
                onMouseDown={(e) => handleMouseDown(e)}
                onMouseUp={(e) => handleMouseUp(e)}
                onTouchEnd={(e) => handleMouseUp(e)}
                onMouseLeave={(e) => handleMouseUp(e)}
                onTouchMove={(e) => handleTouchMove(e)}>
                <img src={fingerImage} alt="" />
                <p>Press to transmit</p>
              </Panel>
            </TurnSectionRight>
          </TurnWrapper>
        </Wrapper>
      )}
    </>
  )
}

const GuessingSection: React.FC<{
  label: string
  section: EmbeddedActivitySection
  sectionState: TurnTakingSectionState
  cardSection: CardDeckInterface
}> = ({ label, section, cardSection, sectionState }) => {
  const participantState = useFocusedParticipantState()
  const selectionState = useSectionStateWithFallback('activity_secret_transmission', section, getInitialSelectionState)
  const { state, dispatch, getBaseAction } = useSessionState()

  const cards = useMemo(
    () =>
      uniqBy(getCards(cardSection), (e) => {
        return e.title.trim().toLowerCase().replace(/\s/g, '')
      }),
    [cardSection]
  )

  const dispatchSectionState = (action: ActionTypes) =>
    dispatch({
      ...getBaseAction(),
      type: DISPATCH_SECTION_STATE,
      property: 'activity_secret_transmission',
      section_id: section.id,
      action,
    })

  const getUserActive = (uid: string) => sectionState[uid] === 'active'

  const handleCardClick = (cardUid: string) => {
    if (participantState)
      dispatchSectionState({
        type: SELECT_CARD,
        participantUid: participantState.profile.uid,
        cardUid: cardUid,
      } as SelectCardAction)
  }

  const handleCardFlip = (cardUid: string) => {
    if (participantState)
      dispatchSectionState({
        type: FLIP_CARD,
        participantUid: participantState.profile.uid,
        cardUid: cardUid,
      } as FlipCardAction)
  }

  return (
    <>
      <GuessingLabel children={label} />
      <GuessingWrapper>
        <ParticipantWrapper>
          {state.participants.map((participant, i, arr) => (
            <Participant key={i} isActive={getUserActive(participant.profile.uid)} width={100 / arr.length}>
              <span>{participant.profile.displayName}</span>
            </Participant>
          ))}
        </ParticipantWrapper>
        <CardWrapper>
          {cards.map((card, i) => {
            const userState = !!participantState
              ? selectionState[participantState.profile.uid] !== undefined
                ? selectionState[participantState.profile.uid]
                : { selection: null }
              : { selection: null }
            const selected = userState.selection === card.uid
            return (
              <CardContainer key={card.id} selected={selected} onClick={() => handleCardClick(card.uid)}>
                <FlipCard
                  card={card}
                  selected={selected}
                  flipped={
                    participantState
                      ? selectionState[participantState.profile.uid]
                        ? selectionState[participantState.profile.uid].flippedCards[card.uid]
                        : false
                      : false
                  }
                  handleFlip={handleCardFlip}
                />
              </CardContainer>
            )
          })}
        </CardWrapper>
      </GuessingWrapper>
    </>
  )
}

const FlipCard: React.FC<{
  card: CardType
  selected: boolean
  flipped: boolean
  handleFlip: (cardUid: string) => void
}> = ({ card, selected, flipped, handleFlip }) => {
  const handleBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    handleFlip(card.uid)
  }

  return (
    <>
      <CardFlip
        isFlipped={flipped}
        containerStyle={{ height: '100%' }}
        children={[
          <Card>
            <AbsoluteWrapper>
              <CardTitle>{card.category?.title}:</CardTitle>
              <CardEmotion>{card.title}</CardEmotion>
              <img src={selected ? gif : staticImg} alt="" />
            </AbsoluteWrapper>
          </Card>,
          <CardBack>
            {card.background_image && <img src={getImageUrl(card.background_image, { preset: '320w-2-1' })} alt="" />}
          </CardBack>,
        ]}
      />
      <Button size="xs" style={{ padding: '2px', margin: 0 }} onClick={(e) => handleBtnClick(e)}>
        <SVG width={20} height={18} viewboxHeight={22} viewboxWidth={24}>
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z" />
        </SVG>
      </Button>
    </>
  )
}

export const SecretTransmissionFacilitator: React.FC<Props> = ({ section, panel, index }) => {
  const { transmission_turn_taking_section, transmission_card_deck_section } = section
  const { state, dispatch, getBaseAction } = useSessionState()
  if (!transmission_turn_taking_section || !transmission_card_deck_section) throw new Error('Missing sections!')

  const cardDeckSectionState = useSectionStateWithFallback('card_deck_sections', transmission_card_deck_section, () =>
    getInitialCardDeckState(state.participants)
  )

  const sectionState = useSectionStateWithFallback('activity_secret_transmission', section, getInitialSelectionState)

  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [resetControls, setResetControls] = useState<number>(0)

  const assignedParticipants = Object.keys(cardDeckSectionState).filter((uid) => !!cardDeckSectionState[uid].card_uid)
  const disableTurnTaking = assignedParticipants.length === 0

  const dispatchSectionState = (action: ActionTypes) =>
    dispatch({
      ...getBaseAction(),
      type: DISPATCH_SECTION_STATE,
      property: 'activity_secret_transmission',
      section_id: section.id,
      action,
    })

  const nextRound = (confirmed: boolean) => {
    setShowConfirm(false)

    if (confirmed) {
      dispatchSectionState({
        type: NEXT_ROUND,
        participants: state.participants,
        deckState: cardDeckSectionState,
      } as NextRoundAction)

      setResetControls(resetControls + 1)
    }
  }

  return (
    <>
      <ActivityInteractionIndicator type="group" />
      <FacilitatorWrapper>
        {transmission_turn_taking_section && (
          <DisabledOverlay active={disableTurnTaking} message="Assign everyone a card to enable turn-taking controls">
            <TurnTakingSectionFacilitator
              hideInteractionIndicator
              property="turn_taking_sections"
              section={transmission_turn_taking_section}
              {...{ panel, index }}
              refresh={resetControls}
              enabledParticipants={assignedParticipants}
              message="Assign cadet a card to unlock individual turn controls">
              <Row justifyContent="flex-end">
                <Button
                  theme="purple"
                  size="s"
                  style={{ marginTop: 20 }}
                  onClick={() => setShowConfirm(true)}
                  children="Next Round"
                />
              </Row>
            </TurnTakingSectionFacilitator>
          </DisabledOverlay>
        )}
        {transmission_card_deck_section && (
          <CardDeckSectionFacilitator
            hideActiveInputs
            property="card_deck_sections"
            section={transmission_card_deck_section}
            highlightUserSelected={Object.keys(sectionState).reduce(
              (acc, uid: string) => {
                acc[uid] = sectionState[uid].selection
                return acc
              },
              {} as { [key: string]: string | null }
            )}
            showCardSubtitle
            refresh={resetControls}
            activityState={sectionState}
            {...{ panel, index }}
          />
        )}
      </FacilitatorWrapper>
      <ConfirmModal
        isOpen={showConfirm}
        onSubmit={nextRound}
        label='Are you sure? This will set all participants to "Wait" and save current selections'
      />
    </>
  )
}

const FacilitatorWrapper = styled.div`
  .highlight span {
    box-shadow:
      inset 0px 0px 0px 3px white,
      0px 0px 5px 2px #05ff00 !important;
  }
`

const Wrapper = styled.div`
  div[type='info'] {
    padding-bottom: 10px;
  }
`

const TurnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const TurnSection = styled.div<{
  backgroundImage?: string
  backgroundColor?: string
  containerHeight: TurnTakingSection['size']
  imageSize?: string
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(p) => containerHeights[p.containerHeight || 'large']}px;
  border: 1px solid #cdd2e4;
  border-radius: 10px;
  background-color: ${(p) => (p.backgroundColor ? p.backgroundColor : '#fff')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${(p) => (p.imageSize ? p.imageSize : 'contain')};
  width: 100%;
  padding: 20px;

  ${(p) =>
    p.backgroundImage
      ? css`
          background-image: url('${p.backgroundImage}');
        `
      : ''}
`

const TurnSectionRight = styled.div<{ backgroundColor?: string }>`
  position: relative;
  width: 150px;
  background-color: ${(p) => (p.backgroundColor ? p.backgroundColor : '#fff')};
  border: 1px solid #cdd2e4;
  border-radius: 10px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${Panel} {
    cursor: pointer;
    width: 100px;

    &.static {
      cursor: auto;
      pointer-events: none;
      opacity: 0.4;
    }

    p {
      pointer-events: none;
      ${fontRegular};
      text-align: center;
      margin: 0 0 10px 0;
      line-height: 1;
      color: #011a46;
      font-size: 14px;
    }

    img {
      pointer-events: none;
      box-sizing: border-box;
      width: 90px;
      height: 90px;
      margin: 5px;
      border: 1px solid #bdc3e0;
      border-radius: 10px;
      /* user-select: none; */
      user-drag: none;
    }
  }
`

const GuessingWrapper = styled.div`
  border: 1px solid #bdc3e0;
  border-radius: 10px;

  ${Button} {
    display: block;
    margin: 20px auto;
    cursor: pointer;
  }
`

const GuessingLabel = styled.p`
  ${fontRegular};
  color: #2eadf0;
  margin-top: 0;
`

const ParticipantWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`

const Participant = styled.div<{ isActive: boolean; width: number }>`
  ${fontLight};
  font-size: 1rem;
  font-weight: ${(p) => (p.isActive ? 500 : 400)};
  color: #011a46;
  width: ${(p) => `${p.width}%`};
  border-right: 1px solid #bdc3e0;
  border-bottom: 1px solid #bdc3e0;
  padding: 15px 25px;

  &:last-of-type {
    border-right: none;
  }

  span {
    opacity: ${(p) => (p.isActive ? 1 : 0.3)};
  }
`

const AbsoluteWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 15px;
  right: 0;
  bottom: 15px;
`

const Card = styled.div`
  position: relative;
  /* user-select: none; */
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10px;
  height: 0;
  padding-top: 50%;

  img {
    position: absolute;
    left: 0;
    top: 15%;
    width: 100%;
    height: 75%;
  }
`

const CardBack = styled(Card)`
  padding: 0;
  height: 0;
  padding-top: 50%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    border-radius: 9px;
    width: 100%;
    height: 100%;
    opacity: 1 !important;
  }
`

const CardTitle = styled.p`
  position: relative;
  z-index: 1;
  ${fontRegular};
  margin: 0;
  font-size: 0.7rem;
`

const CardEmotion = styled.p`
  position: relative;
  ${fontBold};
  text-transform: capitalize;
  margin: 0;
  font-size: 1rem;
  z-index: 1;

  @media (max-width: 920px) {
    font-size: 0.85rem;
  }
`

const CardWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  padding: 0 15px;
`

const CardContainer = styled.div<{ selected?: boolean }>`
  width: 25%;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;

  .react-card-flip {
    margin: 5px;
  }

  .react-card-front,
  .react-card-back {
    border-radius: 10px;
    height: auto !important;
    ${(p) =>
      p.selected
        ? css`
            box-shadow: 0px 0px 5px 5px #05ff00;
          `
        : ''};

    ${Card} {
      background-color: ${(p) => (p.selected ? '#141c33' : '#102032')};
    }
  }

  ${Button} {
    position: absolute;
    bottom: 11px;
    right: 12px;
    padding: 2px;
    margin: 0;

    svg {
      fill: #fff;
    }
  }

  @media (max-width: 920px) {
    width: 33.3%;
  }
`

const LabelBox = styled.div`
  ${fontHeavy}
  font-size: 2rem;
  background: #fff;
  border: 1px solid #cbd2e8;
  border-radius: 10px;
  padding: 5px 10px;
  text-transform: capitalize;
  color: #011a46;
  text-align: center;
`
