import { QuestionnaireSummary, QuestionnaireType, QuestionnaireUser } from 'shared/questionnaires/types'

export interface IntakeSupportResource {
  title: string
  asset: any
  vanityName?: string
}
export interface IntakeSupportResourceGroup {
  title: string
  description?: string
  content: IntakeSupportResource[]
}

export const intakeSupportResources: IntakeSupportResourceGroup[] = [
  {
    title: 'Promotional Materials',
    content: [
      {
        title: 'SAS Communications Toolkit',
        asset: require('../facilitator/resources/assets/intakeSupport/SAS-Communications-Toolkit.pdf'),
      },
      {
        title: 'SAS Family Flyer',
        vanityName: 'SAS-FamilyFlyer-2024 (Digital)',
        asset: require('../facilitator/resources/assets/intakeSupport/SAS-FamilyFlyer-2024(Digital).pdf'),
      },
      {
        title: 'Website Banner - Program Overview',
        asset: require('../facilitator/resources/assets/intakeSupport/ProgramOverview.png'),
      },
      {
        title: 'Website Banner - Child',
        asset: require('../facilitator/resources/assets/intakeSupport/Kids-Banner.png'),
      },
      {
        title: 'Website Banner - Parent',
        asset: require('../facilitator/resources/assets/intakeSupport/Parents-Banner.png'),
      },
    ],
  },
  {
    title: 'Intake & Program Planning',
    content: [
      {
        title: 'Program Planning Checklists (clinic)',
        asset: require('../facilitator/resources/assets/intakeSupport/ProgramChecklists-2023-Clinic.pdf'),
      },
      {
        title: 'Program Planning Checklists (school)',
        asset: require('../facilitator/resources/assets/intakeSupport/ProgramChecklists-2023-School.pdf'),
      },
      {
        title: 'Program Registration Form',
        asset: require('../facilitator/resources/assets/intakeSupport/Program_Registration_Form_2021.doc'),
      },
      {
        title: 'Program Introduction Letter',
        asset: require('../facilitator/resources/assets/intakeSupport/Program_Introduction_Letter_2023.docx'),
      },
      {
        title: 'Caregiver Intake Interview',
        asset: require('../facilitator/resources/assets/intakeSupport/V2-Socialfunctionassessment-ParentInterview-Print-2023.pdf'),
      },
      {
        title: 'Teacher Intake Interview',
        asset: require('../facilitator/resources/assets/intakeSupport/V2-Socialfunctionassessment-TeacherInterview-Print-2023.pdf'),
      },
      {
        title: 'Cadet Intake Interview',
        asset: require('../facilitator/resources/assets/intakeSupport/V2-Socialfunctionassessment-ChildInterview-Print-2021.pdf'),
      },
      {
        title: 'Consenting Adult Guide - Activating a Cadet Place',
        asset: require('../facilitator/resources/assets/intakeSupport/SAS_Guide_for_Consenting_Adult.pdf'),
      },
    ],
  },
]

export interface AssessmentResourceGroup {
  title: string
  booklet: Exclude<QuestionnaireSummary['questionnaire_booklet'], undefined>
  resources: AssessmentResource[]
}

export interface AssessmentResource {
  title: string
  type: QuestionnaireUser
  staticPdf: any
  interactivePdf: any
  questionnaireTypes: QuestionnaireType[]
}

export const assessmentMaterialsResources: AssessmentResourceGroup[] = [
  {
    title: 'Booklet 1 - Pre-program Assessment',
    booklet: 1,
    resources: [
      {
        title: 'Child Questionnaire Booklet 1',
        type: 'cadet',
        questionnaireTypes: ['james-anxiety', 'dylan-anger'],
        staticPdf: require('../facilitator/resources/assets/assessmentMeasures/Child_QuestionnaireBooklet1_2020.pdf'),
        interactivePdf: require('../facilitator/resources/assets/assessmentMeasures/Child_QuestionnaireBooklet1_Interactive_2020.pdf'),
      },
      {
        title: 'Parent Questionnaire Booklet 1',
        type: 'parent',
        questionnaireTypes: ['spence-ssq-parent', 'erssq-parent'],
        staticPdf: require('../facilitator/resources/assets/assessmentMeasures/Parent_QuestionnaireBooklet1_2020.pdf'),
        interactivePdf: require('../facilitator/resources/assets/assessmentMeasures/Parent_QuestionnaireBooklet1_Interactive_2020.pdf'),
      },
      {
        title: 'Teacher Questionnaire Booklet 1',
        type: 'teacher',
        questionnaireTypes: ['spence-ssq-teacher', 'erssq-teacher', 'planning-teacher'],
        staticPdf: require('../facilitator/resources/assets/assessmentMeasures/Teacher_QuestionnaireBooklet1_2020.pdf'),
        interactivePdf: require('../facilitator/resources/assets/assessmentMeasures/Teacher_QuestionnaireBooklet1_Interactive_2020.pdf'),
      },
      {
        title: 'Observational Tool',
        type: 'facilitator',
        questionnaireTypes: ['observational-code'],
        staticPdf: require('../facilitator/resources/assets/assessmentMeasures/Observer_Coding_Form_2020.pdf'),
        interactivePdf: require('../facilitator/resources/assets/assessmentMeasures/Observer_Coding_Form_2020.pdf'),
      },
    ],
  },
  {
    title: 'Booklet 2 - Post-program Assessment',
    booklet: 2,
    resources: [
      {
        title: 'Child Questionnaire Booklet 2',
        type: 'cadet',
        questionnaireTypes: ['james-anxiety', 'dylan-anger', 'feedback-cadet'],
        staticPdf: require('../facilitator/resources/assets/assessmentMeasures/Child_QuestionnaireBooklet2_2020.pdf'),
        interactivePdf: require('../facilitator/resources/assets/assessmentMeasures/Child_QuestionnaireBooklet2_Interactive_2020.pdf'),
      },
      {
        title: 'Parent Questionnaire Booklet 2',
        type: 'parent',
        questionnaireTypes: ['spence-ssq-parent', 'erssq-parent', 'feedback-parent'],
        staticPdf: require('../facilitator/resources/assets/assessmentMeasures/Parent_QuestionnaireBooklet2_2020.pdf'),
        interactivePdf: require('../facilitator/resources/assets/assessmentMeasures/Parent_QuestionnaireBooklet2_Interactive_2020.pdf'),
      },
      {
        title: 'Teacher Questionnaire Booklet 2',
        type: 'teacher',
        questionnaireTypes: ['spence-ssq-teacher', 'erssq-teacher', 'feedback-teacher'],
        staticPdf: require('../facilitator/resources/assets/assessmentMeasures/Teacher_QuestionnaireBooklet2_2020.pdf'),
        interactivePdf: require('../facilitator/resources/assets/assessmentMeasures/Teacher_QuestionnaireBooklet2_Interactive_2020.pdf'),
      },
      {
        title: 'Observational Tool',
        type: 'facilitator',
        questionnaireTypes: ['observational-code'],
        staticPdf: require('../facilitator/resources/assets/assessmentMeasures/Observer_Coding_Form_2020.pdf'),
        interactivePdf: require('../facilitator/resources/assets/assessmentMeasures/Observer_Coding_Form_2020.pdf'),
      },
    ],
  },
  {
    title: 'Booklet 3 - Follow-up 1 Assessment',
    booklet: 3,
    resources: [
      {
        title: 'Child Questionnaire Booklet 3',
        type: 'cadet',
        questionnaireTypes: ['james-anxiety', 'dylan-anger'],
        staticPdf: require('../facilitator/resources/assets/assessmentMeasures/Child_QuestionnaireBooklet3_2020.pdf'),
        interactivePdf: require('../facilitator/resources/assets/assessmentMeasures/Child_QuestionnaireBooklet3_Interactive_2020.pdf'),
      },
      {
        title: 'Parent Questionnaire Booklet 3',
        type: 'parent',
        questionnaireTypes: ['spence-ssq-parent', 'erssq-parent', 'feedback-parent'],
        staticPdf: require('../facilitator/resources/assets/assessmentMeasures/Parent_QuestionnaireBooklet3_2020.pdf'),
        interactivePdf: require('../facilitator/resources/assets/assessmentMeasures/Parent_QuestionnaireBooklet3_Interactive_2020.pdf'),
      },
      {
        title: 'Teacher Questionnaire Booklet 3',
        type: 'teacher',
        questionnaireTypes: ['spence-ssq-teacher', 'erssq-teacher', 'feedback-teacher'],
        staticPdf: require('../facilitator/resources/assets/assessmentMeasures/Teacher_QuestionnaireBooklet3_2020.pdf'),
        interactivePdf: require('../facilitator/resources/assets/assessmentMeasures/Teacher_QuestionnaireBooklet3_Interactive_2020.pdf'),
      },
      {
        title: 'Observational Tool',
        type: 'facilitator',
        questionnaireTypes: ['observational-code'],
        staticPdf: require('../facilitator/resources/assets/assessmentMeasures/Observer_Coding_Form_2020.pdf'),
        interactivePdf: require('../facilitator/resources/assets/assessmentMeasures/Observer_Coding_Form_2020.pdf'),
      },
    ],
  },
  {
    title: 'Booklet 4 - Follow-up 2 Assessment',
    booklet: 4,
    resources: [
      {
        title: 'Child Questionnaire Booklet 4',
        type: 'cadet',
        questionnaireTypes: ['james-anxiety', 'dylan-anger'],
        staticPdf: require('../facilitator/resources/assets/assessmentMeasures/Child_QuestionnaireBooklet4_2020.pdf'),
        interactivePdf: require('../facilitator/resources/assets/assessmentMeasures/Child_QuestionnaireBooklet4_Interactive_2020.pdf'),
      },
      {
        title: 'Parent Questionnaire Booklet 4',
        type: 'parent',
        questionnaireTypes: ['spence-ssq-parent', 'erssq-parent', 'feedback-parent'],
        staticPdf: require('../facilitator/resources/assets/assessmentMeasures/Parent_QuestionnaireBooklet4_2020.pdf'),
        interactivePdf: require('../facilitator/resources/assets/assessmentMeasures/Parent_QuestionnaireBooklet4_Interactive_2020.pdf'),
      },
      {
        title: 'Teacher Questionnaire Booklet 4',
        type: 'teacher',
        questionnaireTypes: ['spence-ssq-teacher', 'erssq-teacher', 'feedback-teacher'],
        staticPdf: require('../facilitator/resources/assets/assessmentMeasures/Teacher_QuestionnaireBooklet4_2020.pdf'),
        interactivePdf: require('../facilitator/resources/assets/assessmentMeasures/Teacher_QuestionnaireBooklet4_Interactive_2020.pdf'),
      },
      {
        title: 'Observational Tool',
        type: 'facilitator',
        questionnaireTypes: ['observational-code'],
        staticPdf: require('../facilitator/resources/assets/assessmentMeasures/Observer_Coding_Form_2020.pdf'),
        interactivePdf: require('../facilitator/resources/assets/assessmentMeasures/Observer_Coding_Form_2020.pdf'),
      },
    ],
  },
  /*
  {
    title: 'SAS Observational Tool',
    resources: [
      {
        title: 'Observational Assessment',
        type: 'other',
        staticPdf: require('../facilitator/resources/assets/assessmentMeasures/Observer_Coding_Form_2020.pdf'),
        interactivePdf: undefined,
      },
    ],
  },
  */
]

export interface HandoutResource {
  title: string
  asset: any
  asset2?: any
  buttonLabel?: string
  button2Label?: string
}

export interface HandoutResourceGroup {
  title: string
  description?: string
  content: HandoutResource[]
}

export const handoutResources: HandoutResourceGroup[] = [
  {
    title: 'Helpful Tools',
    content: [
      {
        title: 'Mission Briefing',
        asset: require('../facilitator/resources/assets/handouts/SAS_Briefing_Sheet_2020.pdf'),
      },
      {
        title: 'Mission Card',
        asset: require('../facilitator/resources/assets/handouts/SAS-Mission-Card_4_2020.pdf'),
      },
      {
        title: 'SAS Virtual Backgrounds',
        asset: require('../facilitator/resources/assets/handouts/SASBackgrounds.zip'),
      },
      {
        title: 'COVID-19 Social Skill Codes',
        asset: require('../facilitator/resources/assets/handouts/SAS-COVID-19-Social-Skill-Codes.zip'),
      },
    ],
  },
  {
    title: 'Skill Tracker Alternatives',
    content: [
      {
        title: 'Skill Tracker Print-Out',
        asset: require('../facilitator/resources/assets/handouts/Printable-SkillTracker-Folded-2021.pdf'),
      },
      {
        title: 'Interactive PDF Skill Tracker',
        asset: require('../facilitator/resources/assets/handouts/SkillTracker_Interactive_2020.pdf'),
      },
    ],
  },
  {
    title: 'SAS Rap Song',
    content: [
      {
        title: 'The Way We Play - Rap song',
        asset: require('../facilitator/resources/assets/handouts/The-Way-We-Play-2023.mp4'),
      },
    ],
  },
  {
    title: 'Session Handouts',
    content: [
      {
        title: 'Spy Plane',
        asset: require('../facilitator/resources/assets/handouts/PAPER_Plane_Diecut_FullWidth_2020.pdf'),
        asset2: require('../facilitator/resources/assets/handouts/PAPER_Plane_Diecut_2020.pdf'),
        buttonLabel: 'Full A4',
        button2Label: 'Cut-Out',
      },
      {
        title: 'DECODER Neural Network',
        asset: require('../facilitator/resources/assets/handouts/DECODER_Print_2020.pdf'),
        asset2: require('../facilitator/resources/assets/handouts/DECODER_Interactive_2020.pdf'),
        buttonLabel: 'Print & Write',
        button2Label: 'Type & Print',
      },
      {
        title: 'Bully Watch File',
        asset: require('../facilitator/resources/assets/handouts/BULLY_Watch_File_Print_2020.pdf'),
        asset2: require('../facilitator/resources/assets/handouts/BULLY_Watch_File_Interactive_2020.pdf'),
        buttonLabel: 'Print & Write',
        button2Label: 'Type & Print',
      },
      {
        title: 'Skill Overview',
        asset: require('../facilitator/resources/assets/handouts/SAS_Skills_Overview_2020.pdf'),
      },
    ],
  },
  {
    title: 'Awards / Certificates',
    content: [
      {
        title: 'Friendship Award',
        asset: require('../facilitator/resources/assets/handouts/FRIENDSHIP_Award_Certificate_Print_2020.pdf'),
        asset2: require('../facilitator/resources/assets/handouts/FRIENDSHIP_Award_Certificate_Interactive_2020.pdf'),
        buttonLabel: 'Print & Write',
        button2Label: 'Type & Print',
      },
      {
        title: 'Teacher Participation Certificate',
        asset: require('../facilitator/resources/assets/handouts/TEACHER_Certificate_Print_2020.pdf'),
        asset2: require('../facilitator/resources/assets/handouts/TEACHER_Certificate_Interactive_2020.pdf'),
        buttonLabel: 'Print & Write',
        button2Label: 'Type & Print',
      },
      {
        title: 'Parent Participation Certificate',
        asset: require('../facilitator/resources/assets/handouts/PARENT_Certificate_Print_2020.pdf'),
        asset2: require('../facilitator/resources/assets/handouts/PARENT_Certificate_Interactive_2020.pdf'),
        buttonLabel: 'Print & Write',
        button2Label: 'Type & Print',
      },
      {
        title: 'Child Graduation Certificate',
        asset: require('../facilitator/resources/assets/handouts/GRADUATION_Certificate_Print_2020.pdf'),
        asset2: require('../facilitator/resources/assets/handouts/GRADUATION_Certificate_Interactive_2020.pdf'),
        buttonLabel: 'Print & Write',
        button2Label: 'Type & Print',
      },
    ],
  },
  {
    title: 'Offline Notes',
    description:
      'Offline notes are provided for you to assist children and parents participate and take notes during meetings when they may have a flat battery or internet glitches. Content can be added to their online notes once connection is re-established in session or during a catch-up meeting. Child Gadget Pack items can be updated anytime through their SAS Digital HQ control room.',
    content: [
      {
        title: 'Generic Offline Meeting Notes (Parent)',
        asset: require('../facilitator/resources/assets/handouts/Mentor-ActivitySheet-2021.pdf'),
      },
      {
        title: 'Offline Club Journal Notes - All Modules',
        asset: require('../facilitator/resources/assets/handouts/offlineJournalNotes-2024.pdf'),
      },
      {
        title: 'Offline Club Journal Notes - Module 1',
        asset: require('../facilitator/resources/assets/handouts/Module-1-ActivitySheet-2021.pdf'),
      },

      {
        title: 'Offline Club Journal Notes - Module 2',
        asset: require('../facilitator/resources/assets/handouts/Module-2-ActivitySheet-2021.pdf'),
      },
      {
        title: 'Offline Club Journal Notes - Module 3',
        asset: require('../facilitator/resources/assets/handouts/Module-3-ActivitySheet-2021.pdf'),
      },
      {
        title: 'Offline Club Journal Notes - Module 4',
        asset: require('../facilitator/resources/assets/handouts/Module-4-ActivitySheet-2021.pdf'),
      },
      {
        title: 'Offline Club Journal Notes - Module 5',
        asset: require('../facilitator/resources/assets/handouts/Module-5-ActivitySheet-2021.pdf'),
      },
      {
        title: 'Offline Club Journal Notes - Module 6',
        asset: require('../facilitator/resources/assets/handouts/Module-6-ActivitySheet-2021.pdf'),
      },
      {
        title: 'Offline Club Journal Notes - Module 7',
        asset: require('../facilitator/resources/assets/handouts/Module-7-ActivitySheet-2021.pdf'),
      },
      {
        title: 'Offline Club Journal Notes - Module 8',
        asset: require('../facilitator/resources/assets/handouts/Module-8-ActivitySheet-2021.pdf'),
      },
      {
        title: 'Offline Club Journal Notes - Module 9',
        asset: require('../facilitator/resources/assets/handouts/Module-9-ActivitySheet-2021.pdf'),
      },
      {
        title: 'Offline Club Journal Notes - Follow Up 1',
        asset: require('../facilitator/resources/assets/handouts/Module-FollowUp-1-ActivitySheet-2021.pdf'),
      },
      {
        title: 'Offline Club Journal Notes - Follow Up 2',
        asset: require('../facilitator/resources/assets/handouts/Module-FollowUp-2-ActivitySheet-2024.pdf'),
      },
    ],
  },
]

export const checklists: { [key: string]: { [key: string]: string } } = {
  cadet: {
    '1': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M1.pdf'),
    '1a': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M1a.pdf'),
    '1b': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M1b.pdf'),
    '2': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M2.pdf'),
    '2a': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M2a.pdf'),
    '2b': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M2b.pdf'),
    '3': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2024-M3.pdf'),
    '3a': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2024-M3a.pdf'),
    '3b': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M3b.pdf'),
    '4': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M4.pdf'),
    '4a': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M4a.pdf'),
    '4b': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M4b.pdf'),
    '5': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M5.pdf'),
    '5a': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M5a.pdf'),
    '5b': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M5b.pdf'),
    '6': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2023-M6.pdf'),
    '6a': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M6a.pdf'),
    '6b': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2023-M6b.pdf'),
    '7': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M7.pdf'),
    '7a': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M7a.pdf'),
    '7b': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M7b.pdf'),
    '8': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M8.pdf'),
    '8a': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M8a.pdf'),
    '8b': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M8b.pdf'),
    '9': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M9.pdf'),
    '9a': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M9a.pdf'),
    '9b': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-M9b.pdf'),
    'follow-up-1': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-FUP1.pdf'),
    'follow-up-1a': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-FUP1a.pdf'),
    'follow-up-1b': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2021-FUP1b.pdf'),
    'follow-up-2': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2024-FUP2.pdf'),
    'follow-up-2a': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2024-FUP2a.pdf'),
    'follow-up-2b': require('../facilitator/resources/assets/checklists/cadet/CadetChecklists-2024-FUP2b.pdf'),
  },
  parent: {
    'parent-intro': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-IntroductoryMeeting.pdf'),
    'parent-info-1': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-InfoSession1.pdf'),
    'parent-info-2': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-InfoSession2.pdf'),
    'parent-info-3': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2023-InfoSession3.pdf'),
    'parent-info-4': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-InfoSession4.pdf'),
    '1': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M1.pdf'),
    '1a': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M1a.pdf'),
    '1b': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M1b.pdf'),
    '2': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M2.pdf'),
    '2a': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M2a.pdf'),
    '2b': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M2b.pdf'),
    '3': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2023-M3.pdf'),
    '3a': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M3a.pdf'),
    '3b': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M3b.pdf'),
    '4': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M4.pdf'),
    '4a': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M4a.pdf'),
    '4b': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M4b.pdf'),
    '5': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2023-M5.pdf'),
    '5a': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M5a.pdf'),
    '5b': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M5b.pdf'),
    '6': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2023-M6.pdf'),
    '6a': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M6a.pdf'),
    '6b': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2023-M6b.pdf'),
    '7': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2023-M7.pdf'),
    '7a': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2023-M7a.pdf'),
    '7b': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2023-M7b.pdf'),
    '8': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M8.pdf'),
    '8a': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M8a.pdf'),
    '8b': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M8b.pdf'),
    '9': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M9.pdf'),
    '9a': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M9a.pdf'),
    '9b': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-M9b.pdf'),
    'follow-up-1': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-FUP1.pdf'),
    'follow-up-1a': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-FUP1a.pdf'),
    'follow-up-1b': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-FUP1b.pdf'),
    'follow-up-2': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2024-FUP2.pdf'),
    'follow-up-2a': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2021-FUP2a.pdf'),
    'follow-up-2b': require('../facilitator/resources/assets/checklists/parent/ParentChecklists-2024-FUP2b.pdf'),
  },
  teacher: {
    teacher: require('../facilitator/resources/assets/checklists/teacher/TeacherChecklists-2023.pdf'),
  },
}
