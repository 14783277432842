import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { SectionPropsBase } from 'shared/session/types'

import { HighlightableArea } from 'session/common/HighlightableArea'
import { sectionComponents, CorkSection } from 'session/common/SectionsPanel'
import { CircleButton } from 'session/common/CircleButton'
import {
  Accordion,
  AccordionInner,
  AccordionTitle,
  AccordionImage,
  AccordionRight,
  AccordionBody,
} from 'session/common/Accordion'

import { getSections } from 'session/reducers'
import { getImageUrl } from 'session/utils/assetUtils'
import { InputContext, useInputContext } from 'session/InputContext'

interface Props extends SectionPropsBase {
  property: 'grouped_sections'
}

export const GroupedSection: React.FC<Props> = ({ property, section, index, panel }) => {
  const { pages } = section
  const [expandedPageIds, setExpandedPageIds] = useState<number[]>([])
  const inputContext = useInputContext()
  return (
    <GroupWrapper>
      <ToggleAllText onClick={() => setExpandedPageIds([])}>Hide All</ToggleAllText>
      <hr />
      {(pages || []).map((page, i) => {
        const sections = getSections(page)
        const expanded = expandedPageIds.indexOf(page.id) >= 0
        const splitTitle = (page.title || '').match(/[^\r\n]+/g) || ['']
        const key = `group${index}_accordion${i}`
        return (
          <Accordion key={i}>
            <HighlightableArea key={key} id={key}>
              <AccordionInner>
                {page.title && (
                  <AccordionTitle>
                    {page.image && (
                      <AccordionImage src={getImageUrl(page.image, { preset: '128w-1' })} alt={page.title} />
                    )}
                    {splitTitle.map((splitTitleElem, i) => (
                      <Fragment key={i}>
                        {splitTitleElem} <br />
                      </Fragment>
                    ))}
                  </AccordionTitle>
                )}
                <AccordionRight
                  onClick={() =>
                    setExpandedPageIds(
                      expanded ? expandedPageIds.filter((id) => id !== page.id) : [...expandedPageIds, page.id]
                    )
                  }>
                  <ShowText>{expanded ? 'HIDE' : 'SHOW'}</ShowText>
                  <CircleButton size="s" children={expanded ? '▲' : '▼'} />
                </AccordionRight>
              </AccordionInner>
            </HighlightableArea>
            <AccordionBody expanded={expanded}>
              {sections
                .map((sectionObj, i) => {
                  const Component = sectionComponents[sectionObj.property]
                  // @ts-ignore
                  return <Component key={i} {...sectionObj} panel={panel} />
                })
                .map((node, i) => {
                  if (panel.theme === 'cork' && sections[i].property !== 'media_grid_sections')
                    return <CorkSection key={i} children={node} />
                  return node
                })
                .map((node, i) => (
                  <InputContext.Provider
                    key={i}
                    children={node}
                    value={{
                      ...inputContext,
                      owner: sections[i].property,
                      owner_id: sections[i].section.id,
                    }}
                  />
                ))
                .map((node, i) => {
                  const key = `group${index}_${sections[i].property}_${sections[i].index}`
                  return <HighlightableArea key={key} id={key} children={node} />
                })}
            </AccordionBody>
            <hr />
          </Accordion>
        )
      })}
    </GroupWrapper>
  )
}

export const GroupedSectionFacilitator = GroupedSection

const GroupWrapper = styled.div`
  hr {
    opacity: 0.5;
  }
`

export const ToggleAllText = styled.div`
  color: #011a46;
  font-size: 14px;
  line-height: 1;
  text-align: right;
  padding-bottom: 8px;
  /* user-select: none; */
  cursor: pointer;
`

export const ShowText = styled.div`
  color: #011a46;
  margin-right: 16px;
  font-size: 14px;
  font-weight: 400;
`
