import React, { CSSProperties } from 'react'
import styled, { css } from 'styled-components'
import { SCENE_ORIG_WIDTH, SCENE_ORIG_HEIGHT } from './constants'
import { SceneObject } from './sceneObjects'
import { svgs } from './assets'

export interface SceneObjectProps {
  sceneObject: SceneObject
  onSelect: () => void
  onHover: (exit: boolean) => void
  active?: boolean
  interactive: boolean
  isHovered: boolean
}

export const InteractiveSceneObject: React.FC<SceneObjectProps> = ({
  sceneObject,
  onSelect,
  active,
  interactive,
  isHovered,
  onHover,
}) => {
  const style: CSSProperties = {
    left: `${(sceneObject.position.left / SCENE_ORIG_WIDTH) * 100}%`,
    top: `${(sceneObject.position.top / SCENE_ORIG_HEIGHT) * 100}%`,
    width: `${((sceneObject.imageSize.width * 2) / SCENE_ORIG_WIDTH) * 100}%`,
    height: `${((sceneObject.imageSize.height * 2) / SCENE_ORIG_HEIGHT) * 100}%`,
  }
  return (
    <SceneObjectContainer
      style={style}
      interactive={interactive}
      onClick={!active && interactive ? onSelect : undefined}
      onMouseEnter={() => {
        if (!active && interactive) {
          onHover(false)
        }
      }}
      onMouseLeave={() => {
        if (!active && interactive) {
          onHover(true)
        }
      }}>
      {!active && svgs[sceneObject.hitbox]}
      <SceneObjectImage imageUrl={sceneObject.image} active={active || isHovered} />
    </SceneObjectContainer>
  )
}

const cover = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const SceneObjectImage = styled.div<{ imageUrl: any; active?: boolean }>`
  ${cover}
  pointer-events: none;
  background-image: url('${(p) => p.imageUrl}');
  background-position: left top;
  background-size: 100% auto;
  background-repeat: no-repeat;
  opacity: ${(p) => (p.active ? 1 : 0)};
  transition: opacity 0.25s linear;
`

const SceneObjectContainer = styled.div<{ interactive: boolean }>`
  position: absolute;

  /* user-select: none; */
  -webkit-touch-callout: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  pointer-events: ${(p) => (p.interactive ? 'auto' : 'none')};

  & svg {
    ${cover}
    opacity: 0;
    pointer-events: none;

    & > * {
      pointer-events: painted;
      cursor: ${(p) => (p.interactive ? 'pointer' : 'not-allowed')};
    }
    ${(p) =>
      p.interactive
        ? css`
            &:hover + ${SceneObjectImage} {
              opacity: 1;
            }
          `
        : ''}
  }
`
