import addMinutes from 'date-fns/addMinutes'
import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import React, { useCallback, useState } from 'react'
import ReactDOM from 'react-dom'
import { ThemeName, blue, parentGreen, purple } from 'themes'

import { UserType, useGenericUser } from 'app/useGenericUser'
import { CadetProgressLookupEntity, GroupEntity, SessionEntity } from 'shared/dashboard/types'

import { createPdf, getModuleMetadata, getPastSharedSessionInputValues } from 'api'
import { useUserState } from 'app/UserState'
import { postJson } from 'utils/apiUtils'
import { arrayContainsAny } from 'utils/arrayUtils'
import { getGroupPublicName } from 'utils/titleUtils'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'
import { useApiEndpoint, useEndpoint } from 'dashboards/utils/endpointHooks'
import { getSessionStartDate } from 'dashboards/utils/sessionUtils'
import { getModuleTitle } from 'dashboards/constant/moduleCodes'
import { baseUrl } from 'dashboards/facilitator/FacilitatorDashboard'

import { AnchorButton, LinkButton } from 'common/LinkButton'
import { Spinner, SpinnerWithLabel } from 'common/Spinner'
import { Button, Column, H3, Hr, Row, Spacer } from 'common/ui'
import { Cell, CellInner } from './Cell'

import { InfoTooltip } from 'common/Tooltip'
import { CircleButton } from 'session/common/CircleButton'
import { ShowText } from 'training/sections/GroupedSection'
import { TableOfContents, TableOfContentsResponse } from './TableOfContents'
import { TodoListSectionState } from 'shared/session/types'
import { PdfModal, PdfStatus } from 'common/PdfModal'
import { MentorChecklistPrintPayload, mentorChecklistTemplateUrl } from 'session/printable/MentorChecklistPrint'
import { checklists } from 'dashboards/constant/resources'
import { sortByKey } from 'utils/sortUtils'

interface Props {
  session: SessionEntity & { group?: GroupEntity }
  group?: GroupEntity
  hideGroupTitle?: boolean
  userType: UserType
  context: 'past' | 'future'
  onUpdate?: (newSession: SessionEntity) => void
  onTocExpanded?: () => void
  mentorUid?: string
  preventStart?: boolean
  preventStartCallback?: () => void
}

const sessionTypeTitleMap: { [key in SessionEntity['type']]: string } = {
  cadet: 'Cadet Club Meeting',
  parent: 'Parent Group Meeting',
  facilitator: 'Facilitator Meeting', // not used i don't think
}

const sessionTypeThemeMap: { [key in SessionEntity['type']]: ThemeName } = {
  cadet: 'blue',
  parent: 'parentGreen',
  facilitator: 'purple',
}

const sessionTypeHexMap: { [key in SessionEntity['type']]: string } = {
  cadet: blue.buttonBorderTopColor,
  parent: parentGreen.buttonBorderTopColor,
  facilitator: purple.buttonBorderTopColor,
}

export const SessionCell: React.FC<Props> = ({
  session,
  hideGroupTitle,
  userType,
  group: _group,
  context,
  mentorUid,
  onUpdate,
  onTocExpanded,
  preventStart,
  preventStartCallback,
}) => {
  const group = _group || session.group
  const missingFacilitator = group ? group.facilitator_id === null : false
  const { accessToken } = useUserState()
  const parsedDate = getSessionStartDate(session)
  const hasStarted = parsedDate ? isAfter(new Date(), parsedDate) : false
  const startingSoon = parsedDate ? isAfter(new Date(), addMinutes(parsedDate, -10)) : false
  const shouldBeFinished = parsedDate ? isAfter(new Date(), addMinutes(parsedDate, 120)) : false

  const [finalizing, setFinalizing] = useState<boolean>(false)
  const [sendingReminder, setSendingReminder] = useState<boolean>(false)
  const [updatingCatchUpMode, setUpdatingCatchUpMode] = useState<boolean>(false)
  const [tocExpanded, setTocExpanded] = useState<boolean>(false)

  const user = useGenericUser()
  const isFacilitator =
    user.roles.find((role) => role === 'sas-sg facilitator' || role === 'sas-sg senior facilitator') !== undefined

  const isPrimaryFacilitator = group?.facilitator_id === +user.uid.split('-')[1]
  const isCoFacilitator = group?.cofacilitator_id === +user.uid.split('-')[1]

  const shouldFetchProgressionEvents =
    userType === 'facilitator' && context === 'past' && isFacilitator && (isPrimaryFacilitator || isCoFacilitator)

  const [sessionProgressionEvents, { loaded: loadedProgressionEvents, loading: loadingProgressionEvents }] =
    useEndpoint<CadetProgressLookupEntity[]>(
      shouldFetchProgressionEvents ? `/api/v1/sessions/${session.uid}/potential_attendees` : null,
      []
    )
  const completedProgressEvents = sessionProgressionEvents
    ? sessionProgressionEvents.filter(({ completed }) => completed)
    : []

  const shouldFetchTodoData = userType === 'mentor' && context === 'past' && session.type === 'parent'
  const [todoData, { loaded: todoDataLoaded, errorLoading: errorLoadingTodoData }] = useApiEndpoint<
    typeof getPastSharedSessionInputValues<TodoListSectionState>
  >(
    getPastSharedSessionInputValues<TodoListSectionState>,
    shouldFetchTodoData,
    {
      session_uid: session.uid,
      name: 'todo_list_state',
      include_current_session: true,
      mentor_uid: userType === 'mentor' ? mentorUid : undefined,
    },
    accessToken
  )
  const todoState = (todoData || []).sort(sortByKey('updated_at', 'descending'))[0]?.value || null

  const [skippedSlides] = useApiEndpoint<typeof getPastSharedSessionInputValues<boolean>>(
    getPastSharedSessionInputValues<boolean>,
    shouldFetchTodoData,
    {
      session_uid: session.uid,
      owner: 'skipped_slides',
      include_current_session: true,
      mentor_uid: userType === 'mentor' ? mentorUid : undefined,
    },
    accessToken
  )
  const skippedSlideUids = (skippedSlides || []).map(({ name }) => name)
  const todoSkipped = !!todoState && !!todoState.slideUid && skippedSlideUids.includes(todoState.slideUid)

  const sessionCanHaveToc = session.type === 'cadet' || session.type === 'parent'
  const [moduleMetadata, { loading: loadingMetadata }] = useApiEndpoint<typeof getModuleMetadata>(
    getModuleMetadata,
    sessionCanHaveToc,
    session.type,
    session.module_code,
    accessToken
  )
  const [toc, { loading: loadingToc }] = useEndpoint<TableOfContentsResponse>(
    moduleMetadata && moduleMetadata.toc_enabled && sessionCanHaveToc && tocExpanded
      ? `/api/v1/modules/${session.type}/${session.module_code}/toc?session_uid=${session.uid}`
      : null
  )

  const sessionIsActive = context === 'future' && (hasStarted || startingSoon)
  const needsCatchUp = sessionProgressionEvents
    ? completedProgressEvents.length < sessionProgressionEvents.length
    : false

  const handleSendReminder = () => {
    if (sendingReminder) return
    setSendingReminder(true)
    postJson(`/api/v1/sessions/${session.uid}/email_reminder`, {}, getAuthRequestParams(accessToken))
      .then(() => {
        setSendingReminder(false)
      })
      .catch((e) => setSendingReminder(false))
  }

  const handleUpdateCatchUp = (enable: boolean = true) => {
    if (updatingCatchUpMode) return
    setUpdatingCatchUpMode(true)
    postJson(
      `/api/v1/sessions/${session.uid}/${enable ? 'enable' : 'disable'}_catch_up`,
      {},
      getAuthRequestParams(accessToken)
    )
      .then((newSession: SessionEntity) => {
        setUpdatingCatchUpMode(false)
        if (onUpdate) onUpdate(newSession)
      })
      .catch((e) => {
        setUpdatingCatchUpMode(false)
      })
  }

  const handleFinalize = () => {
    if (finalizing) return
    setFinalizing(true)
    postJson(`/api/v1/sessions/${session.uid}/finalize`, {}, getAuthRequestParams(accessToken))
      .then((newSession: SessionEntity) => {
        setFinalizing(false)
        if (onUpdate) onUpdate(newSession)
      })
      .catch((e) => setFinalizing(false))
  }

  return (
    <>
      <Cell
        accent={sessionTypeThemeMap[session.type]}
        active={sessionIsActive || (!session.finalized && needsCatchUp && !session.catch_up_active)}
        highlighted={session.catch_up_active}
        disabled={missingFacilitator && !session.finalized}>
        <CellInner>
          <Row justifyContent="space-between" alignItems="center" flexWrap={true} style={{ width: '100%' }}>
            <Column flex="1 1 auto" margin={[5, 0]}>
              <H3
                style={
                  userType === 'mentor' && context === 'past'
                    ? { color: session.type === 'parent' ? '#4EBE40' : '#22acf3' }
                    : {}
                }>
                {context === 'future' ? (
                  <>{hasStarted ? '[ IN PROGRESS ] ' : startingSoon ? ' [ STARTING SOON ] ' : ''}</>
                ) : (
                  <>{session.catch_up_active ? '[ CATCH UP ] ' : ''}</>
                )}
                {sessionTypeTitleMap[session.type]} - {getModuleTitle(session.module_code)}
              </H3>
              <span>
                {!hideGroupTitle &&
                  group &&
                  `${userType === 'facilitator' ? group.name : getGroupPublicName(group)} - `}
                {parsedDate && format(parsedDate, 'ccc d LLL yyyy, h:mma')}
              </span>
            </Column>
            {userType === 'facilitator' && (
              <Row flex="none" margin={[5, 0]}>
                {context === 'future' && (
                  <>
                    {!hasStarted && isFacilitator && !missingFacilitator && (
                      <>
                        <Button
                          type="button"
                          size="s"
                          children={sendingReminder ? 'Sending...' : 'Send Reminder'}
                          disabled={sendingReminder}
                          onClick={() => handleSendReminder()}
                        />
                        <Spacer size="s" />
                      </>
                    )}
                    {(!preventStart ||
                      session.type !== 'cadet' ||
                      !startingSoon ||
                      (isFacilitator &&
                        !session.finalized &&
                        !session.concluded &&
                        shouldBeFinished &&
                        !missingFacilitator)) && (
                      <LinkButton
                        size="s"
                        children={startingSoon ? 'Join' : 'Prepare'}
                        to={`/facilitator/meetings/live/${session.uid}`}
                      />
                    )}
                    {preventStart &&
                      session.type === 'cadet' &&
                      startingSoon &&
                      !(isFacilitator && !session.finalized && !session.concluded && shouldBeFinished) && (
                        <Button
                          style={{ cursor: 'pointer' }}
                          size="s"
                          children={'Join'}
                          onClick={preventStartCallback}
                        />
                      )}
                    {isFacilitator && isPrimaryFacilitator && (
                      <>
                        <Spacer size="s" />
                        <LinkButton size="s" children="Edit" to={`${baseUrl}/meetings/${session.uid}/edit`} />
                      </>
                    )}
                  </>
                )}
                {context === 'past' && (
                  <>
                    {session.finalized ? (
                      <>
                        {arrayContainsAny(user.roles, ['sas-sg facilitator', 'sas-sg senior facilitator']) ? (
                          <LinkButton size="s" children="Review" to={`/facilitator/meetings/past/${session.uid}`} />
                        ) : (
                          <>
                            <Spacer size="s" />
                            <img src={require('reporting/assets/images/tick-circle.svg')?.default} alt="" />
                          </>
                        )}
                      </>
                    ) : loadingProgressionEvents ? (
                      <Spinner color="#18A0FB" />
                    ) : (
                      <>
                        {session.catch_up_active && !missingFacilitator && (
                          <>
                            <LinkButton size="s" children="Join" to={`/facilitator/meetings/live/${session.uid}`} />
                            <Spacer size="s" />
                            <Button
                              type="button"
                              size="s"
                              children="Stop Catch-up"
                              disabled={updatingCatchUpMode}
                              onClick={() => handleUpdateCatchUp(false)}
                            />
                          </>
                        )}
                        {session.type !== 'cadet' && !missingFacilitator && (
                          <>
                            <Spacer size="s" />
                            <Button
                              type="button"
                              size="s"
                              children={`Finalise & Release to Mentors`}
                              disabled={finalizing}
                              onClick={() => handleFinalize()}
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Row>
            )}
            {userType === 'mentor' && session.type === 'parent' && context === 'future' && !missingFacilitator && (
              <LinkButton
                size="s"
                children={'Join'}
                to={`/mentor/${mentorUid}/meetings/live/${session.uid}`}
                disabled={!startingSoon}
                theme="parentGreen"
              />
            )}
            {/* <pre>{JSON.stringify(skippedSlides, null, 2)}</pre> */}
            {/* <pre>{JSON.stringify(todoData[0], null, 2)}</pre> */}
            {shouldFetchTodoData && !todoSkipped && (
              <>
                {!todoDataLoaded ? (
                  <AnchorButton size="s" disabled theme="parentGreen">
                    To Do List&nbsp;
                    {errorLoadingTodoData ? '⚠️' : <Spinner size={12} style={{ verticalAlign: 'middle' }} />}
                  </AnchorButton>
                ) : !todoState ? (
                  // checklist session data doesn't exist so fallback to old checklist material
                  checklists.parent[session.module_code] ? (
                    <AnchorButton
                      size="s"
                      target="_blank"
                      theme="parentGreen"
                      href={checklists.parent[session.module_code]}>
                      To Do List (Legacy)
                    </AnchorButton>
                  ) : (
                    // can't find old checklist material with session module_code so...
                    <span style={{ fontSize: 11, textAlign: 'center' }}>
                      To do list
                      <br />
                      unavailable
                    </span>
                  )
                ) : todoState.pdfUrl ? (
                  <AnchorButton
                    size="s"
                    theme="parentGreen"
                    children={'To Do List'}
                    href={todoState.pdfUrl}
                    target="_blank"
                    download
                  />
                ) : (
                  <TodoListDownloadButton
                    todoListState={todoState}
                    moduleTitle={(moduleMetadata && moduleMetadata.public_title) || getModuleTitle(session.module_code)}
                    moduleDate={format(
                      new Date((session.finalized_datetime || session.start_datetime) * 1000),
                      'd MMM yyyy'
                    )}
                  />
                )}
                <Spacer size="s" />
              </>
            )}
            {userType === 'mentor' && session.type === 'parent' && context === 'past' && (
              <LinkButton
                size="s"
                theme="parentGreen"
                children={'Meeting Notes'}
                to={`/mentor/${mentorUid}/meetings/past/${session.uid}`}
              />
            )}
            {userType === 'mentor' && session.type === 'cadet' && context === 'past' && (
              <LinkButton size="s" children={'View'} to={`/mentor/${mentorUid}/club-journals/${session.uid}`} />
            )}
          </Row>
        </CellInner>
        {userType === 'facilitator' &&
          isFacilitator &&
          !session.finalized &&
          !session.concluded &&
          shouldBeFinished && (
            <>
              <Hr />
              <CellInner>
                <small style={{ color: '#FF2928', fontWeight: 600 }}>
                  Meeting attendance was not marked as complete. <br />
                  Please rejoin the session and complete the details on the end-screen.
                </small>
              </CellInner>
            </>
          )}
        {userType === 'mentor' &&
          sessionCanHaveToc &&
          context === 'past' &&
          !loadingMetadata &&
          moduleMetadata &&
          moduleMetadata.toc_enabled && (
            <CellInner
              justifyContent="flex-end"
              paddingTop={10}
              paddingLeft={12}
              paddingRight={12}
              marginLeft="0 !important"
              marginRight="0 !important"
              style={{ borderTop: '1px solid #BDC3E0' }}>
              <Row
                alignItems="center"
                onClick={() => {
                  setTocExpanded(!tocExpanded)
                  onTocExpanded?.()
                }}
                style={{ cursor: 'pointer' }}>
                <ShowText style={{ marginRight: 10 }}>{tocExpanded ? 'HIDE Contents' : 'SHOW Contents'}</ShowText>
                <CircleButton
                  style={{ cursor: 'pointer' }}
                  size="xs"
                  theme="parentGreen"
                  children={tocExpanded ? '▲' : '▼'}
                />
              </Row>
            </CellInner>
          )}
        {shouldFetchProgressionEvents && (
          <>
            <Hr />
            <CellInner>
              {loadedProgressionEvents && sessionProgressionEvents ? (
                <>
                  {session.catch_up_active && userType === 'facilitator' && isFacilitator && !session.finalized && (
                    <small style={{ color: '#18A0FB', fontWeight: 600 }}>
                      Complete this catch-up prior to the group's next Club Meeting.
                    </small>
                  )}
                  {!session.catch_up_active && (
                    <Column flex="1 1 auto">
                      <H3>{session.type === 'cadet' ? 'Cadet Status' : 'Attendance'}</H3>
                      <span>
                        {completedProgressEvents.length} / {sessionProgressionEvents.length} completed
                      </span>
                    </Column>
                  )}
                  {session.type === 'cadet' && (
                    <>
                      {!session.finalized && !missingFacilitator && (
                        <Row flex="none" alignItems="center">
                          {!session.catch_up_active && (
                            <InfoTooltip
                              iconSize={14}
                              placement="left"
                              content={`Participants wont have access to this meeting's notes/journal until finalised`}
                            />
                          )}
                          {needsCatchUp && !session.catch_up_active && (
                            <>
                              <Spacer size="s" />
                              <Button
                                type="button"
                                size="s"
                                children={updatingCatchUpMode ? `Activating...` : `Start as Catch-Up`}
                                disabled={updatingCatchUpMode}
                                onClick={() => handleUpdateCatchUp(true)}
                              />
                            </>
                          )}
                          {!session.catch_up_active && (
                            <>
                              <Spacer size="s" />
                              <Button
                                type="button"
                                size="s"
                                children={`Finalise & Release to Cadets`}
                                disabled={finalizing}
                                onClick={() => handleFinalize()}
                              />
                            </>
                          )}
                        </Row>
                      )}
                    </>
                  )}
                </>
              ) : (
                <Column flex="1 1 auto">
                  {!session.catch_up_active && !session.finalized ? (
                    <small style={{ color: '#18A0FB', fontWeight: 600 }}>
                      Complete this catch-up prior to the group's next Club Meeting.
                    </small>
                  ) : (
                    <>
                      <H3>Cadet Status</H3>
                      <SpinnerWithLabel
                        color={sessionTypeHexMap[session.type]}
                        label="Loading attendance..."
                        size={20}
                        labelStyle={{ fontSize: 12 }}
                        containerStyle={{ margin: 0 }}
                      />
                    </>
                  )}
                </Column>
              )}
            </CellInner>
          </>
        )}
        {userType !== 'facilitator' && sessionCanHaveToc && mentorUid && (
          <TableOfContents
            loading={loadingToc}
            contents={tocExpanded ? toc : null}
            meetingUrl={`/mentor/${mentorUid}${session.type === 'cadet' ? '/club-journals/' : '/meetings/past/'}${
              session.uid
            }`}
          />
        )}
      </Cell>
    </>
  )
}

const TodoListDownloadButton: React.FC<{
  todoListState: TodoListSectionState
  moduleTitle: string
  moduleDate: string
}> = ({ todoListState, moduleTitle, moduleDate }) => {
  const [pdfStatus, setPdfStatus] = useState<PdfStatus>('ready')
  const [pdfUrl, setPdfUrl] = useState('')
  const { accessToken } = useUserState()

  const handlePdfDownload = useCallback(() => {
    if (todoListState.pdfUrl) {
      // this if statement shouldn't get hit because we conditionally render a different download button if sessionState.pdfUrl exists
      // but just in case...
      ReactDOM.unstable_batchedUpdates(() => {
        setPdfStatus('success')
        setPdfUrl(todoListState.pdfUrl!)
      })
    } else {
      setPdfStatus('busy')
      const pdfPayload: MentorChecklistPrintPayload = {
        module: moduleTitle,
        date: moduleDate,
        items: todoListState.items,
      }
      createPdf<MentorChecklistPrintPayload>(mentorChecklistTemplateUrl, pdfPayload, accessToken, {
        footerTemplate: 'footer-mono',
      })
        .then((pdf) => {
          ReactDOM.unstable_batchedUpdates(() => {
            setPdfStatus('success')
            setPdfUrl(pdf.url)
          })
        })
        .catch(() => setPdfStatus('error'))
    }
  }, [accessToken, moduleDate, moduleTitle, todoListState.items, todoListState.pdfUrl])

  return (
    <>
      <Button size="s" theme="parentGreen" children="To Do List" onClick={handlePdfDownload} />
      <PdfModal
        status={pdfStatus}
        url={pdfUrl}
        onClose={() => setPdfStatus('ready')}
        onCancel={() => setPdfStatus('ready')}
        text1="Generating PDF"
      />
    </>
  )
}
