import React, { Fragment } from 'react'
import { NavRouteProps } from 'dashboards/types'
import { Page } from 'dashboards/common/Page'
import { Cells } from 'dashboards/common/Cell'
import { intakeSupportResources } from '../../constant/resources'
import { ResourceCell } from 'dashboards/common/ResourceCell'
import { Spacer } from 'common/ui'
import { HeaderHr } from 'dashboards/common/HeaderHr'

export const IntakeSupport: React.FC<NavRouteProps> = ({ route }) => {
  return (
    <Page route={route}>
      {intakeSupportResources.map((resourceSet, n) => (
        <Fragment key={n}>
          <HeaderHr children={resourceSet.title} />
          <Cells>
            {resourceSet.content.map((item, i) => (
              <ResourceCell key={i} title={item.title} asset={item.asset} vanityName={item.vanityName} />
            ))}
          </Cells>
          <Spacer size="l" />
        </Fragment>
      ))}
    </Page>
  )
}
