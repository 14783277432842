import styled, {
  CSSProperties,
  createGlobalStyle,
  InterpolationFunction,
  css,
  keyframes,
} from 'styled-components/macro'
import { lighten, darken } from 'utils/polishedUtils'
import { fontBlack, fontHeavy, fontRegular, fontLight, fontBold } from 'fonts'
import { appendPxToNumeric } from 'utils/appendPxToNumeric'
import { AppPanelTheme } from 'app/AppPanelTheme'
import { Theme, ThemeName, ALL_THEMES, gray } from 'themes'

const IE11 = window.navigator.userAgent.match(/Trident\/7/)

interface UserSelectNoneProps {
  userSelectNone?: boolean
}

export const getTheme = (props: { theme: Theme | ThemeName }): Theme => {
  if (typeof props.theme === 'string') return ALL_THEMES[props.theme] || gray
  else return props.theme || gray
}

const userSelectNoneLogic: InterpolationFunction<UserSelectNoneProps> = ({ userSelectNone = false }) => {
  return userSelectNone ? 'user-select: none; -webkit-touch-callout: none;' : ''
}

export interface FlexProps {
  flex?: boolean | string
}

export const flexLogic: InterpolationFunction<FlexProps> = ({ flex }) => {
  return `
    flex: ${flex === true ? 'auto' : flex || 'none'};
  `
}

interface FlexWrapProps {
  flexWrap?: boolean
}

const flexWrapLogic: InterpolationFunction<FlexWrapProps> = ({ flexWrap }) => {
  return `
    flex-wrap: ${flexWrap ? 'wrap' : 'none'};
  `
}

interface FlexAlignProps {
  alignItems?: CSSProperties['alignItems']
  alignSelf?: CSSProperties['alignSelf']
  alignContent?: CSSProperties['alignContent']
  justifyContent?: CSSProperties['justifyContent']
}

const flexAlignLogic: InterpolationFunction<FlexAlignProps> = ({
  alignItems,
  alignSelf,
  alignContent,
  justifyContent,
}) => {
  return `
    ${alignItems ? `align-items: ${alignItems};` : ''}
    ${alignSelf ? `align-self: ${alignSelf};` : ''}
    ${alignContent ? `align-content: ${alignContent};` : ''}
    ${justifyContent ? `justify-content: ${justifyContent};` : ''}
  `
}

export type MarginSize = 'xs' | 's' | 'm' | 'l'
type MarginValue = boolean | number | string | MarginSize

interface MarginProps {
  margin?: MarginValue | [MarginValue, MarginValue] | [MarginValue, MarginValue, MarginValue, MarginValue]
  marginTop?: MarginValue
  marginRight?: MarginValue
  marginBottom?: MarginValue
  marginLeft?: MarginValue
}

export const margins: { [key in MarginSize]: string } = { xs: '5px', s: '10px', m: '15px', l: '30px' }

function marginValueToString(margin: MarginValue): string {
  if (margin === true) return margins['m']
  if (margin === false) return '0'
  if (typeof margin === 'number') return `${margin}px`
  return margins[margin as MarginSize] || margin
}

const marginLogic: InterpolationFunction<MarginProps> = ({
  margin = 0,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
}) => {
  let css = ''
  if (Array.isArray(margin)) {
    css += `margin: ${margin.map(marginValueToString).join(' ')};`
  } else if (margin !== undefined) {
    css += `margin: ${marginValueToString(margin)};`
  }
  if (marginTop !== undefined) css += `margin-top: ${marginValueToString(marginTop)};`
  if (marginRight !== undefined) css += `margin-right: ${marginValueToString(marginRight)};`
  if (marginBottom !== undefined) css += `margin-bottom: ${marginValueToString(marginBottom)};`
  if (marginLeft !== undefined) css += `margin-left: ${marginValueToString(marginLeft)};`
  return css
}

type PaddingSize = 'xs' | 's' | 'm' | 'l'
type PaddingValue = boolean | number | string | PaddingSize

interface PaddingProps {
  padding?: PaddingValue | [PaddingValue, PaddingValue] | [PaddingValue, PaddingValue, PaddingValue, PaddingValue]
  paddingTop?: PaddingValue
  paddingRight?: PaddingValue
  paddingBottom?: PaddingValue
  paddingLeft?: PaddingValue
}

const paddings = { xs: '5px', s: '10px', m: '15px', l: '30px' }

function paddingValueToString(padding: PaddingValue): string {
  if (padding === true) return paddings['m']
  if (padding === false) return '0'
  if (typeof padding === 'number') return `${padding}px`
  return paddings[padding as PaddingSize] || padding
}

export const paddingLogic: InterpolationFunction<PaddingProps> = ({
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
}) => {
  let css = ''
  if (Array.isArray(padding)) {
    css += `padding: ${padding.map(paddingValueToString).join(' ')};`
  } else if (padding !== undefined) {
    css += `padding: ${paddingValueToString(padding)};`
  }
  if (paddingTop !== undefined) css += `padding-top: ${paddingValueToString(paddingTop)};`
  if (paddingRight !== undefined) css += `padding-right: ${paddingValueToString(paddingRight)};`
  if (paddingBottom !== undefined) css += `padding-bottom: ${paddingValueToString(paddingBottom)};`
  if (paddingLeft !== undefined) css += `padding-left: ${paddingValueToString(paddingLeft)};`
  return css
}

const Flex = styled.div<FlexProps & FlexWrapProps & FlexAlignProps & MarginProps & PaddingProps & UserSelectNoneProps>`
  position: relative;
  display: flex;
  ${flexLogic}
  ${flexWrapLogic}
  ${flexAlignLogic}
  ${marginLogic}
  ${paddingLogic}
  ${userSelectNoneLogic}
`

interface ColumnProps {
  reverse?: boolean
}

export const Column = styled(Flex)<ColumnProps>`
  flex-direction: ${(p) => (p.reverse ? 'column-reverse' : 'column')};
  ${(p) => (p.flex === true && IE11 ? 'flex-basis: 100%;' : '')}
`

type Breakpoint = 'm' | 's' | 'xs'

const breakpoints = { m: 960, s: 640, xs: 320 }

interface RowProps {
  reverse?: boolean
  responsive?: number | boolean | Breakpoint
}

export const Row = styled(Flex)<RowProps>`
  flex-direction: ${(p) => (p.responsive === true ? 'column' : p.reverse ? 'row-reverse' : 'row')};

  ${(p) =>
    p.responsive && typeof p.responsive !== 'boolean'
      ? `@media (max-width: ${
          typeof p.responsive === 'number' ? p.responsive : breakpoints[p.responsive]
        }px) { flex-direction: column; }`
      : ''}
`

export const RowWrapPortrait = styled(Row)<{ mediaQuery?: string }>`
  @media ${(p) => p.mediaQuery || '(orientation: portrait)'} {
    flex-wrap: wrap;
    & > * {
      flex-basis: auto;
    }
    & > [class*='Spacer'] {
      min-width: 100%;
    }
  }
`

const paddingSize = { l: '20px', m: '15px', s: '10px', xs: '5px' }

export const Padding = styled.div<{ size?: keyof typeof paddingSize }>`
  display: flex;
  flex: auto;
  flex-direction: inherit;
  padding: ${(p) => paddingSize[p.size || 'm']};
`

export interface PanelProps extends FlexProps, MarginProps, PaddingProps, UserSelectNoneProps {
  direction?: 'column' | 'row'
  flair?: string[]
  shadow?: boolean
  shadowColor?: 'dark' | 'black' | string
}

function panelShadowColor(p: any) {
  switch (p.shadowColor) {
    case 'dark':
      return getTheme(p).darkBg
    case 'black':
      return 'rgba(0,0,0,0.3)'
  }
  return p.shadowColor || getTheme(p).panelShadowColor
}

export const Panel = styled.div<PanelProps>`
  ${flexLogic}
  ${marginLogic}
  ${paddingLogic}
  ${userSelectNoneLogic}
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: ${(p) => p.direction || 'column'};
  border-radius: ${(p) => getTheme(p).borderRadius};
  border-bottom: 5px solid #c4cae6;
  ${(p) => (p.shadow !== false ? `box-shadow: 0px 5px 10px 0px ${panelShadowColor(p)};` : '')}
  background: ${(p) => (p.flair || []).concat([getTheme(p).panelBg]).join(',')};
  background-repeat: no-repeat;
  -webkit-print-color-adjust: exact;
`
export type SpacerSize = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl'
export interface SpacerProps extends FlexProps {
  size?: SpacerSize
  inline?: boolean
}

const spacerSize = { xl: '60px', l: '30px', m: '15px', s: '10px', xs: '5px', xxs: '2px' }

export const Spacer = styled.div<SpacerProps>`
  ${flexLogic}
  width: ${(p) => spacerSize[p.size || 'm']};
  height: ${(p) => spacerSize[p.size || 'm']};
  ${(p) => (p.inline ? 'display: inline-block;' : '')}
`

export type ContainerSize = 'xs' | 's' | 'm' | 'l' | 'xl'
export const containerSize = { xs: 320, s: 568, m: 768, l: 1024, xl: 1280 }

export const Container = styled.div<MarginProps & { size?: ContainerSize; gutter?: MarginSize }>`
  ${marginLogic}
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${(p) => (p.gutter ? margins[p.gutter] : 0)};
  padding-right: ${(p) => (p.gutter ? margins[p.gutter] : 0)};
  width: ${(p) => containerSize[p.size || 'l']}px;
  max-width: 100%;
`

interface TextAlignProps {
  align?: 'left' | 'center'
}

export interface ThemeProps {
  theme?: Theme | ThemeName
}

const textAlignLogic: InterpolationFunction<TextAlignProps> = ({ align }) => {
  return `text-align: ${align || 'inherit'};`
}

interface HeadingProps extends MarginProps, TextAlignProps {}

export const headingCss = css<HeadingProps>`
  ${textAlignLogic}
  ${marginLogic}

  color: #001947;
  line-height: 1.2;
`

export const H1 = styled.h1<HeadingProps>`
  ${headingCss}
  ${fontBlack}
  font-size: 22px;
`

export const H2 = styled.h2<HeadingProps>`
  ${headingCss}
  ${fontBlack}
  font-size: 14px;
`

export const H3 = styled.h3<HeadingProps>`
  ${headingCss}
  ${fontHeavy}
  font-size: 12px;
`

export const SmallCaps = styled.small<HeadingProps>`
  ${headingCss}
  ${fontHeavy}
  text-transform: uppercase;
  padding-bottom: 0.25em;
`

export const Title = styled.h2<HeadingProps>`
  ${headingCss}
  ${fontHeavy}
  text-transform: none;
  font-size: 18px;
  letter-spacing: 0.5px;
`

export type ButtonSize = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl'
export interface ButtonProps extends FlexProps, MarginProps {
  size?: ButtonSize
  pressed?: boolean
  flashing?: boolean
  selected?: boolean
  shadowColor?: 'panel' | 'black' | 'theme' | 'dark' | string
  theme?: Theme | ThemeName
  withChevron?: boolean
  ghostMode?: boolean
  circle?: boolean
}

export const buttonFontSize = { xl: '26px', l: '20px', m: '16px', s: '14px', xs: '12px', xxs: '10px' }
export const buttonPadding = {
  xl: '20px 40px',
  l: '18px 35px',
  m: '15px 30px',
  s: '8px 16px',
  xs: '4px 8px',
  xxs: '2px 5px',
}
export const buttonBorder = { xl: '5px', l: '4px', m: '4px', s: '3px', xs: '2px', xxs: '1px' }
export const buttonPressedBorder = { xl: '4px', l: '3px', m: '3px', s: '2px', xs: '1px', xxs: '1px' }
export const buttonBorderRadius = { xl: '12px', l: '10px', m: '10px', s: '8px', xs: '4px', xxs: '3px' }
export const boxShadowSize = {
  xl: '0 5px 8px 0px',
  l: '0px 3px 5px 0px',
  m: '0px 3px 5px 0px',
  s: '0px 2px 2px 0px',
  xs: '0px 2px 2px 0px',
  xxs: '0px 1px 1px 0px',
}

export const buttonFlash = keyframes`
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
`

function buttonShadowColor(p: any) {
  switch (p.shadowColor) {
    case 'theme':
      return getTheme(p).appPanelShadowColor
    case 'panel':
      return 'rgba(72,80,162,0.45)'
    case 'dark':
      return getTheme(p).darkBg
    case 'black':
      return 'rgba(0,0,0,0.3)'
  }
  return p.shadowColor || 'rgba(72,80,162,0.45)'
}

export const BaseButton = styled.button<ButtonProps>`
  ${fontHeavy}
  ${marginLogic}
    ${flexLogic}
    
    user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  position: relative;
  cursor: default;
  outline: none;

  font-size: ${(p) => buttonFontSize[p.size || 'm']};
  padding: ${(p) => buttonPadding[p.size || 'm']};
  letter-spacing: 0;

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
    box-shadow: none;
  }
`

export const Button = styled(BaseButton)`
  border-radius: ${(p) => (p.circle ? '999px' : buttonBorderRadius[p.size || 'm'])};
  border-style: solid;
  border-width: ${(p) => buttonBorder[p.size || 'm']};
  border-color: ${(p) => getTheme(p).buttonBorderTopColor};

  ${(p) => css`
    background: linear-gradient(
      180deg,
      ${getTheme(p).buttonBackgroundTopColor} 0%,
      ${getTheme(p).buttonBackgroundBottomColor} 100%
    );
  `}
  color: ${(p) => getTheme(p).buttonTextColor} !important;
  ${(p) => css`
    box-shadow: ${boxShadowSize[p.size || 'm']} ${buttonShadowColor(p)}
      ${p.selected ? `, inset 0px 0px 25px -5px rgba(0,0,0,0.75)` : ''};
  `}
  transition: opacity 0.2s ease-out;
  opacity: 1;
  overflow: visible;

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: -${(p) => buttonBorder[p.size || 'm']};
    left: -${(p) => buttonBorder[p.size || 'm']};
    right: -${(p) => buttonBorder[p.size || 'm']};
    border-radius: ${(p) => (p.circle ? '999px' : buttonBorderRadius[p.size || 'm'])};
    border-color: ${(p) => getTheme(p).buttonBorderBottomColor};
    border-style: solid;
    border-width: 0;
    border-bottom-width: ${(p) => buttonBorder[p.size || 'm']};
  }

  /* flashing outline and chevron fight for control lol */
  ${(p) =>
    p.flashing
      ? css`
          &::before {
            content: '';
            position: absolute;
            top: -${buttonBorder[p.size || 'm']};
            left: -${buttonBorder[p.size || 'm']};
            right: -${buttonBorder[p.size || 'm']};
            bottom: -${buttonBorder[p.size || 'm']};
            border-radius: ${buttonBorderRadius[p.size || 'm']};
            box-shadow: 0px 0px 5px 5px ${getTheme(p).highlightColor};
            animation: ${buttonFlash} 0.5s linear infinite alternate;
          }
        `
      : p.withChevron
        ? css`
            padding-right: 2em;
            &::before {
              content: '>';
              position: absolute;
              top: 50%;
              right: 0.62em;
              transform: translateY(calc(-50% - 0.05em));
              font-size: 1.2em;
              font-weight: 100;
              line-height: 1;
            }
          `
        : ''}

  ${(p) => (p.selected ? ':enabled,' : '')}
  &:hover,
    &:focus {
    border-color: ${(p) => getTheme(p).buttonFocusBorderTopColor};
    background: ${(p) =>
      `linear-gradient(180deg, ${getTheme(p).buttonFocusBackgroundTopColor} 0%, ${
        getTheme(p).buttonFocusBackgroundBottomColor
      } 100%)`};

    &::after {
      border-color: ${(p) => getTheme(p).buttonFocusBorderBottomColor};
    }
  }

  ${(p) => (p.pressed && !p.selected ? ':enabled,' : '')}
  &:active {
    box-shadow: none;
  }

  ${(p) => (p.pressed || p.selected ? ':enabled,' : '')}
  &:active {
    transform: translateY(1px);

    &::after {
      border-bottom-width: ${(p) => buttonPressedBorder[p.size || 'm']};
    }
  }

  /* IDK, thanks scotty I guess :/ */
  ${(p) =>
    p.ghostMode
      ? css`
          &::after {
            content: none;
          }
          border: 1px solid white;
          background: none;
          background-color: rgba(255, 255, 255, 0.35);
          color: white;
          box-shadow: none;
          ${p.selected ? ':enabled,' : ''}
          &:hover, &:focus {
            background: none;
            background-color: rgba(255, 255, 255, 0.5);
            border-color: white;
          }
        `
      : ''}
`

export const IconButton = styled(Button)`
  padding: 0;

  /* increase hit size for icon buttons */
  &:before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    /* we need to unset the "flashing" animation */
    border-radius: none;
    box-shadow: none;
    animation: none;
  }
`

export const ButtonUnderlay = styled.div<{ size?: ButtonProps['size'] }>`
  background: #fff;
  border-radius: ${(p) => buttonBorderRadius[p.size || 'm']};
`

interface RadioactiveButtonProps {
  inactive?: boolean
  rotated?: boolean
  lighten?: number
}

export const RadioactiveButton = styled(BaseButton)<ButtonProps & RadioactiveButtonProps>`
  ${(p) => css`
    background: linear-gradient(
      to ${p.rotated ? 'right' : 'bottom'},
      ${lighten(p.lighten || 0, '#72c116')},
      ${lighten(p.lighten || 0, '#9add4c')} 50%,
      ${lighten(p.lighten || 0, '#72c116')}
    );
  `}
  color: #19345d !important;
  border-radius: 100px;
  text-transform: none;
  line-height: 1;
  border: none;
  transition: opacity 0.2s linear;
  opacity: 1;

  ${(p) =>
    p.selected
      ? css`
          box-shadow:
            0 0 14px 0 rgba(58, 154, 35, 0.5),
            inset 0 0 0 3px ${darken(0.25, '#9add4c')};
        `
      : ''}

  &:not(:hover):not(:focus)${(p) => (!p.inactive ? ':not(:enabled)' : '')} {
    opacity: 0.6;
    box-shadow: none;
  }

  &:hover,
  &:focus {
    background: linear-gradient(to bottom, #9add4c, #c1eb8f 50%, #9add4c);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 100px;
    top: ${(p) => (p.selected ? '4px' : '2px')};
    left: ${(p) => (p.selected ? '4px' : '2px')};
    right: ${(p) => (p.selected ? '4px' : '2px')};
    bottom: ${(p) => (p.selected ? '4px' : '2px')};
  }
  &::before {
    background: linear-gradient(
      to ${(p) => (p.rotated ? 'right' : 'bottom')},
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0) 25%,
      rgba(255, 255, 255, 0)
    );
  }
  &::after {
    background: linear-gradient(
      to ${(p) => (p.rotated ? 'left' : 'top')},
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0) 25%,
      rgba(255, 255, 255, 0)
    );
  }
  &:active::before {
    top: 3px;
  }
  &:active::after {
    bottom: 1px;
  }
`

interface HrProps {
  margin?: boolean | 'xs' | 's' | 'm' | 'l'
}

const hrMargin = { l: '30px 0', m: '15px 0', s: '10px 0', xs: '5px 0' }

export const Hr = styled.div<HrProps>`
  align-self: stretch;
  border-top: 1px solid #c4cae5;
  border-bottom: 1px solid #fff;
  margin: ${(p) => (p.margin === true ? hrMargin['m'] : (p.margin && hrMargin[p.margin]) || 0)};
`

interface VrProps {
  margin?: boolean | 'xs' | 's' | 'm'
}

const vrMargin = { m: '0 15px', s: '0 10px', xs: '0 5px' }

export const Vr = styled.div<VrProps>`
  align-self: stretch;
  border-left: 1px solid #c4cae5;
  border-right: 1px solid #fff;
  margin: ${(p) => (p.margin === true ? vrMargin['m'] : (p.margin && vrMargin[p.margin]) || 0)};
`

interface ColorProps {
  color?: string
}

export const P = styled.p<TextAlignProps & ColorProps>`
  ${fontRegular}
  ${textAlignLogic}
  font-size: 16px;
  line-height: 1.3;
  color: ${(p) => p.color || '#001947'};

  & a,
  & a:visited,
  & a:active,
  & a:focus {
    color: ${(p) => p.theme.buttonBorderTopColor};
  }
`

export const CollapseMargin = styled.div<{ top?: boolean; bottom?: boolean }>`
  ${({ top = true }) => (top ? `> *:first-child { margin-top: 0 }` : '')}
  ${({ bottom = true }) => (bottom ? `> *:last-child { margin-bottom: 0 }` : '')}
`

interface PositionProps {
  cover?: boolean
  left?: string | number
  top?: string | number
  right?: string | number
  bottom?: string | number
}

interface ZIndexProps {
  zIndex?: number
}

export const Absolute = styled.div<PositionProps & ZIndexProps & UserSelectNoneProps>`
  position: absolute;
  display: flex;
  flex-direction: inherit;
  ${(p) => (p.left !== undefined || p.cover ? `left: ${appendPxToNumeric(p.left) || 0};` : '')}
  ${(p) => (p.top !== undefined || p.cover ? `top: ${appendPxToNumeric(p.top) || 0};` : '')}
  ${(p) => (p.right !== undefined || p.cover ? `right: ${appendPxToNumeric(p.right) || 0};` : '')}
  ${(p) => (p.bottom !== undefined || p.cover ? `bottom: ${appendPxToNumeric(p.bottom) || 0};` : '')}
  ${(p) => (p.zIndex !== undefined ? `z-index: ${p.zIndex};` : '')}
  ${userSelectNoneLogic}
`

export const Fixed = styled.div<PositionProps & ZIndexProps>`
  position: fixed;
  display: flex;
  flex-direction: inherit;
  ${(p) => (p.left !== undefined || p.cover ? `left: ${appendPxToNumeric(p.left) || 0};` : '')}
  ${(p) => (p.top !== undefined || p.cover ? `top: ${appendPxToNumeric(p.top) || 0};` : '')}
  ${(p) => (p.right !== undefined || p.cover ? `right: ${appendPxToNumeric(p.right) || 0};` : '')}
  ${(p) => (p.bottom !== undefined || p.cover ? `bottom: ${appendPxToNumeric(p.bottom) || 0};` : '')}
  ${(p) => (p.zIndex !== undefined ? `z-index: ${p.zIndex};` : '')}
  
  @media print {
    position: relative;
    width: 100%;
  }
`

export const Center = styled(Flex)`
  flex-direction: inherit;
  align-items: center;
  justify-content: center;
  ${flexAlignLogic}
`

export const AppGlobalStyle = createGlobalStyle`
html {
  min-height: 100%;
  display: flex;
}

body {
  ${IE11 ? 'min-height: 100vh;' : ''}
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* -webkit-touch-callout: none;
  user-select: none; */
}

body,
#root {
  display: flex;
  flex: auto;
  position: relative;
}  
`

export const AppBackground = styled(AppPanelTheme)<ThemeProps>`
  flex: auto;
  display: flex;
  background: repeating-linear-gradient(
      90deg,
      transparent 0px,
      transparent 15px,
      rgba(255, 255, 255, 0.2) 15px,
      rgba(255, 255, 255, 0.2) 16px,
      transparent 16px,
      transparent 75px
    ),
    repeating-linear-gradient(
      0deg,
      transparent 0px,
      transparent 15px,
      rgba(255, 255, 255, 0.2) 15px,
      rgba(255, 255, 255, 0.2) 16px,
      transparent 16px,
      transparent 75px
    ),
    ${(p) =>
      `linear-gradient(20deg, ${getTheme(p).appBackgroundBottomColor} 20%, ${getTheme(p).appBackgroundTopColor} 90%)`};
  background-size:
    75px 75px,
    75px 75px,
    100% 100%;
`

export const JournalBackground = styled.div<ThemeProps>`
  flex: auto;
  display: flex;
  background: repeating-linear-gradient(
      90deg,
      transparent 0px,
      transparent 15px,
      rgba(255, 255, 255, 0.05) 15px,
      rgba(255, 255, 255, 0.05) 16px,
      transparent 16px,
      transparent 75px
    ),
    repeating-linear-gradient(
      0deg,
      transparent 0px,
      transparent 15px,
      rgba(255, 255, 255, 0.05) 15px,
      rgba(255, 255, 255, 0.05) 16px,
      transparent 16px,
      transparent 75px
    ),
    ${(p) =>
      `linear-gradient(209deg, ${getTheme(p).journalBackgroundTopColor} 0%, ${
        getTheme(p).journalBackgroundBottomColor
      } 100%)`};
  background-size:
    75px 75px,
    75px 75px,
    100% 100%;
`

export const CUT_TL = `url(${require('common/assets/image/cut-tl@2x.png')}) top left / 155px 35px`
export const CUT_TR = `url(${require('common/assets/image/cut-tr@2x.png')}) top right / 155px 35px`
export const CUT_BL = `url(${require('common/assets/image/cut-bl@2x.png')}) bottom left / 155px 35px`
export const CUT_BR = `url(${require('common/assets/image/cut-br@2x.png')}) bottom right / 155px 35px`
export const CUT_BL_L = `url(${require('common/assets/image/cut-large-bl@2x.png')}) bottom left / 335px 40px`
export const CUT_BR_L = `url(${require('common/assets/image/cut-large-br@2x.png')}) bottom right / 335px 40px`
export const CUT_TR_S = `url(${require('common/assets/image/cut-small-tr@2x.png')}) top right / 120px 20px`
export const CUT_BL_S = `url(${require('common/assets/image/cut-small-bl@2x.png')}) bottom left / 120px 20px`

export const CUT_TL_M = `url(${require('common/assets/image/cut-tl-m@2x.png')}) top left / 300px 35px`
export const CUT_TR_M = `url(${require('common/assets/image/cut-tr-m@2x.png')}) top right / 300px 35px`
export const CUT_BL_M = `url(${require('common/assets/image/cut-bl-m@2x.png')}) bottom left / 300px 35px`
export const CUT_BR_M = `url(${require('common/assets/image/cut-br-m@2x.png')}) bottom right / 300px 35px`

export const GRILL_TL = `url(${require('common/assets/image/grill-left@2x.png')}) left 30px top 35px / 60px 15px`
export const GRILL_TR = `url(${require('common/assets/image/grill-right@2x.png')}) right 30px top 35px / 60px 15px`
export const GRILL_TL_S = `url(${require('common/assets/image/grill-left@2x.png')}) left 30px top 25px / 60px 15px`
export const GRILL_TR_S = `url(${require('common/assets/image/grill-right@2x.png')}) right 30px top 25px / 60px 15px`
export const GRILL_BL_S = `url(${require('common/assets/image/grill-left@2x.png')}) left 30px bottom 25px / 60px 15px`
export const GRILL_BR_S = `url(${require('common/assets/image/grill-right@2x.png')}) right 30px bottom 25px / 60px 15px`
export const TAB_B_L = `url(${require('common/assets/image/tab-large-bottom@2x.png')}) center bottom / 100px 18px`
export const TAB_B_S = `url(${require('common/assets/image/tab-small-bottom@2x.png')}) center bottom / 70px 18px`
export const TAB_B_XL = `url(${require('common/assets/image/tab-bottom-xl@2x.png')}) center bottom / 330px 20px`
export const TAB_T_L = `url(${require('common/assets/image/tab-large-top@2x.png')}) center top / 100px 18px`
export const TAB_T_S = `url(${require('common/assets/image/tab-small-top@2x.png')}) center top / 70px 18px`
export const TAB_T_XL = `url(${require('common/assets/image/tab-top-xl@2x.png')}) center top / 330px 20px`
export const TAB_L_DARK = `url(${require('common/assets/image/tab-left-dark@2x.png')}) center left / 17px 95px`
export const TAB_R_DARK = `url(${require('common/assets/image/tab-right-dark@2x.png')}) center right / 17px 95px`
export const TAB_L_S = `url(${require('common/assets/image/tab-small-left@2x.png')}) center left / 18px 70px`
export const TAB_R_S = `url(${require('common/assets/image/tab-small-right@2x.png')}) center right / 18px 70px`

export const TAB_B_DARK = `url(${require('common/assets/image/tab-b-dark.svg')?.default}) center bottom`
export const TAB_T_DARK = `url(${require('common/assets/image/tab-t-dark.svg')?.default}) center top`
export const CUT_BR_DARK = `url(${require('common/assets/image/cut-br-dark.svg')?.default}) bottom right`
export const CUT_BL_DARK = `url(${require('common/assets/image/cut-bl-dark.svg')?.default}) bottom left`
export const CUT_TR_DARK = `url(${require('common/assets/image/cut-tr-dark.svg')?.default}) top right`
export const CUT_TL_DARK = `url(${require('common/assets/image/cut-tl-dark.svg')?.default}) top left`

export const Form = styled.form`
  display: flex;
  flex: auto;
  flex-direction: inherit;
`

export const GradientField = styled.div<TextAlignProps & ThemeProps>`
  ${textAlignLogic}
  ${fontBlack}
  background: ${(p) =>
    `linear-gradient(-90deg, ${getTheme(p).appBackgroundTopColor} 0%, ${getTheme(p).appBackgroundBottomColor} 100%)`};
  padding: 2px 15px 4px 15px;
  border-radius: 10px;
  border-top: 3px solid #c4cae6;
  color: white;
  font-size: 20px;
  box-shadow: inset 0 3px 3px -1px rgba(0, 0, 0, 0.4);
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`

export const Textarea = styled.textarea<{ error?: boolean; inputSize?: InputSize } & FlexProps & ThemeProps>`
  ${flexLogic}
  ${fontRegular}
  font-size: ${(p) => inputFontSize[p.inputSize || 'm']};
  color: ${(p) => p.theme.darkBg};
  background: white;
  border-radius: ${(p) => inputBorderRadius[p.inputSize || 'm']};
  padding: ${(p) => inputPadding[p.inputSize || 'm']};

  box-sizing: border-box;
  background: white;
  border: 1px solid #abb4db;
  box-shadow: 0px 2px 0px 0px #fff;
  resize: none;
  outline: none;

  &:focus {
    border-color: ${(p) => getTheme(p).buttonBorderTopColor};
  }

  @media print {
    &::placeholder {
      color: transparent;
    }
    &::-webkit-input-placeholder {
      color: transparent;
    }
    &:-moz-placeholder {
      color: transparent;
    }
    &::-moz-placeholder {
      color: transparent;
    }
    &:-ms-input-placeholder {
      color: transparent;
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

export type InputSize = 's' | 'm' | 'l'
export const inputFontSize = { s: '14px', m: '16px', l: '20px' }
export const inputPadding = { s: '8px 12px', m: '10px 15px', l: '12px 20px' }
export const inputBorder = { s: '3px', m: '4px', l: '4px' }
export const inputBorderRadius = { s: '8px', m: '10px', l: '12px' }

export const inputMixin = css<{ error?: boolean; inputSize?: InputSize } & FlexProps & ThemeProps>`
  ${fontRegular}
  font-size: ${(p) => inputFontSize[p.inputSize || 'm']};
  color: ${(p) => p.theme.darkBg};
  background: white;
  border-radius: ${(p) => inputBorderRadius[p.inputSize || 'm']};
  padding: ${(p) => inputPadding[p.inputSize || 'm']};

  background: white;
  border: 1px solid #abb4db;
  box-shadow: 0px 2px 0px 0px #fff;
  resize: none;
  outline: none;

  &:focus {
    border-color: ${(p) => getTheme(p).buttonBorderTopColor};
  }

  @media print {
    &::placeholder {
      color: transparent;
    }
    &::-webkit-input-placeholder {
      /* WebKit browsers */
      color: transparent;
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: transparent;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: transparent;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: transparent;
    }
  }
`

export const Input = styled.input<{ error?: boolean; inputSize?: InputSize } & FlexProps & ThemeProps>`
  ${flexLogic}
  ${inputMixin}
`

export const TextInput = styled.input<{ error?: boolean; inputSize?: InputSize } & ThemeProps>`
  position: relative;
  width: 100%;
  margin: 0;
  -webkit-appearance: none;
  outline: none;

  border: none;
  border-top: ${(p) => inputBorder[p.inputSize || 's']} solid #d5d9ed;
  box-sizing: border-box;
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: ${(p) => inputBorderRadius[p.inputSize || 's']};

  font-size: ${(p) => inputFontSize[p.inputSize || 's']};
  padding: ${(p) => inputPadding[p.inputSize || 's']};
  padding-bottom: calc(
    ${(p) => inputPadding[p.inputSize || 's'].split(' ')[0]} + ${(p) => inputBorder[p.inputSize || 's']}
  );
  color: #001947;

  ${(p) => (p.error ? ':enabled,' : '')} :focus {
    box-shadow:
      inset ${(p) => boxShadowSize[p.inputSize || 's']} rgba(0, 0, 0, 0.1),
      0 0 0 3px ${(p) => (p.error ? 'red' : getTheme(p).buttonBorderTopColor)};
    width: calc(100% - ${(p) => inputBorder[p.inputSize || 's']});
  }

  &:disabled {
    opacity: 0.6;
  }

  @media print {
    &::-webkit-input-placeholder {
      /* WebKit browsers */
      color: transparent;
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: transparent;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: transparent;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: transparent;
    }
  }
`

export const TextareaInput = styled.textarea<{ error?: boolean; inputSize?: InputSize } & ThemeProps>`
  position: relative;
  width: 100%;
  margin: 0;
  -webkit-appearance: none;
  outline: none;
  resize: vertical;

  border: none;
  border-top: ${(p) => inputBorder[p.inputSize || 's']} solid #d5d9ed;
  box-sizing: border-box;
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: ${(p) => inputBorderRadius[p.inputSize || 's']};

  font-size: ${(p) => inputFontSize[p.inputSize || 's']};
  padding: ${(p) => inputPadding[p.inputSize || 's']};
  padding-bottom: calc(
    ${(p) => inputPadding[p.inputSize || 's'].split(' ')[0]} + ${(p) => inputBorder[p.inputSize || 's']}
  );
  color: #001947;

  ${(p) => (p.error ? ':enabled,' : '')} :focus {
    box-shadow:
      inset ${(p) => boxShadowSize[p.inputSize || 's']} rgba(0, 0, 0, 0.1),
      0 0 0 3px ${(p) => (p.error ? 'red' : getTheme(p).buttonBorderTopColor)};
    width: calc(100% - ${(p) => inputBorder[p.inputSize || 's']});
  }

  @media print {
    &::-webkit-input-placeholder {
      /* WebKit browsers */
      color: transparent;
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: transparent;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: transparent;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: transparent;
    }
  }
`

export const Label = styled.label<{}>`
  font-size: 12px;
  margin-bottom: 5px;
`

export const Slab = styled(Row)<{ interactive?: boolean; error?: boolean; disabled?: boolean }>`
  width: 100%;
  align-items: center;
  border: 1px solid ${(p) => (p.error ? 'orange' : p.theme.thinOutlineColor)};
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  ${(p) => (p.disabled ? 'opacity: 0.5;' : '')}

  &${(p) => (p.interactive ? ':hover' : ':not(:enabled)')} {
    border-color: ${(p) => p.theme.thinOutlineActiveColor};
  }
`

interface OutlineButtonProps extends ButtonProps {
  filled?: boolean
}

export const OutlineButton = styled.button<OutlineButtonProps>`
  ${fontRegular}
  ${marginLogic}
    ${flexLogic}
    
    user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  font-size: ${(p) => buttonFontSize[p.size || 'm']};
  border-radius: ${(p) => buttonBorderRadius[p.size || 'm']};
  border-style: solid;
  border-width: 1px;
  border-color: ${(p) => getTheme(p).buttonBorderTopColor};
  background: ${(p) => (p.filled ? getTheme(p).buttonBorderTopColor : 'transparent')};

  color: ${(p) => (p.filled ? '#fff' : getTheme(p).buttonBorderBottomColor)};
  padding: ${(p) => buttonPadding[p.size || 'm']};
  outline: none;
  position: relative;
  transition: opacity 0.2s ease-out;
  opacity: 1;
  overflow: visible;

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  ${(p) => (p.selected ? ':enabled,' : '')}
  &:hover,
    &:focus {
    color: ${(p) => (p.filled ? '#fff' : getTheme(p).buttonFocusBorderBottomColor)};
    border-color: ${(p) => getTheme(p).buttonFocusBorderTopColor};
    background: ${(p) => (p.filled ? getTheme(p).buttonFocusBorderTopColor : 'rgba(255, 255, 255, 0.5)')};
  }

  ${(p) => (p.pressed || p.selected ? ':enabled,' : '')}
  &:active {
    border-color: ${(p) =>
      p.filled ? getTheme(p).buttonFocusBorderBottomColor : getTheme(p).buttonFocusBorderTopColor};
    background: ${(p) => (p.filled ? getTheme(p).buttonFocusBorderBottomColor : 'white')};
  }

  &::before {
    content: ${(p) => (p.flashing ? '""' : 'none')};
    position: absolute;
    top: -${(p) => buttonBorder[p.size || 'm']};
    left: -${(p) => buttonBorder[p.size || 'm']};
    right: -${(p) => buttonBorder[p.size || 'm']};
    bottom: -${(p) => buttonBorder[p.size || 'm']};
    border-radius: ${(p) => buttonBorderRadius[p.size || 'm']};
    box-shadow: 0px 0px 5px 5px ${(p) => getTheme(p).highlightColor};
    animation: ${buttonFlash} 0.5s linear infinite alternate;
  }
`

export const IconOutlineButton = styled(OutlineButton)`
  padding: 0;

  /* increase hit size for icon buttons */
  &:before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    /* we need to unset the "flashing" animation */
    border-radius: none;
    box-shadow: none;
    animation: none;
  }
`

interface TextProps extends TextColorProps, TextAlignProps, FontSizeProps, FontFamilyProps, MarginProps, PaddingProps {
  allCaps?: boolean
}

interface TextColorProps {
  color?: 'theme' | string
}

interface FontFamilyProps {
  font?: 'light' | 'regular' | 'bold' | 'heavy' | 'black'
}

export interface FontSizeProps {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | number
}

const fontFamilyLogic: InterpolationFunction<FontFamilyProps> = ({ font = 'regular' }) => {
  switch (font) {
    case 'light':
      return fontLight
    case 'bold':
      return fontBold
    case 'heavy':
      return fontHeavy
    case 'black':
      return fontBlack
    case 'regular':
    default:
      return fontRegular
  }
}

function textColor(p: any) {
  switch (p.color) {
    case 'success':
      return '#357f2e'
    case 'theme':
      return getTheme(p).buttonBackgroundTopColor
    case 'dark':
      return getTheme(p).darkBg
  }
  return p.color || getTheme(p).darkBg
}

const textSizes = { xs: '12px', s: '14px', m: '16px', l: '18px', xl: '24px' }

export const Text = styled.div<TextProps>`
  ${marginLogic}
  ${paddingLogic}
  ${fontFamilyLogic}
  ${textAlignLogic}

  color: ${(p) => textColor(p)};
  font-size: ${(p) => (typeof p.size === 'number' ? `${p.size}px` : textSizes[p.size || 'm'])};
  ${(p) => (p.allCaps ? 'text-transform: uppercase;' : '')};
  ${(p) => (p.allCaps && p.font !== 'black' ? 'letter-spacing: 0.05em;' : '')}

  a {
    color: inherit;
  }

  a:focus,
  a:hover {
    text-decoration: none;
  }
`

// const headingSizes = { s: '14px', m: '18px', l: '24px', xl: '32px' }
