import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { fontRegular } from 'fonts'
import { mix } from 'utils/polishedUtils'
import {
  SectionPropsBase,
  EmotionometerSection as EmotionometerSectionType,
  Section,
  InputValueContext,
} from 'shared/session/types'
import { SectionTitle } from 'session/common/SectionTitle'
import { InputComponent } from 'session/common/InputComponent'
import { useFacilitatorState, useFocusedParticipantState } from 'session/hooks/useProfileState'
import { P } from 'common/ui'
import { useSessionState } from 'session/SessionState'
import { InputContext, useInputContext } from 'session/InputContext'

interface Props extends SectionPropsBase {
  property: 'emotionometer_sections'
}

export const EmotionometerSection: React.FC<Props> = ({ property, section, index }) => {
  const facilitatorState = useFacilitatorState()
  const participantState = useFocusedParticipantState()
  const { isFacilitator } = useSessionState()
  const { title, _owner, _owner_id, order, ...props } = section

  const inputContext = useInputContext()
  const newInputContext: InputValueContext = { ...inputContext }
  if (section.usage === 'facilitator') {
    newInputContext.participant_uid = 'shared'
  }

  const disabled =
    (section.usage === 'individual' && !!facilitatorState && !participantState) ||
    (section.usage === 'facilitator' && !!participantState)

  return (
    <>
      {title && <SectionTitle children={title} />}
      {section.usage === 'individual' && !!facilitatorState && !participantState && (
        <P>[ Select participant tab to view emotionometer ]</P>
      )}
      {(!isFacilitator || section.usage !== 'individual' || participantState) && (
        <InputContext.Provider value={newInputContext}>
          <EmotionometerComponent {...props} disabled={disabled} />
        </InputContext.Provider>
      )}
    </>
  )
}

export const EmotionometerSectionFacilitator = EmotionometerSection

interface EmotionometerProps extends Omit<EmotionometerSectionType, keyof Section> {
  disabled?: boolean
}

export const EmotionometerComponent: React.FC<EmotionometerProps> = ({
  start,
  end,
  step,
  labels,
  theme,
  usage,
  disabled,
}) => {
  const range = end - start
  const [overIndex, setOverIndex] = useState<number | null>(null)
  return (
    <>
      <EmotionometerScale onMouseLeave={() => setOverIndex(null)}>
        <InputComponent<number | null> name="index" defaultValue={null}>
          {({ value: valueIndex, onChange }) => (
            <>
              {[...Array(range / step + 1)].map((_, i) => (
                <EmotionometerUnitContainer key={i}>
                  <EmotionometerUnit
                    themeColor={emotionometerThemes[theme]}
                    background={getUnitColor(theme, i, range)}
                    interactive={!disabled}
                    over={overIndex === i}
                    hovering={overIndex !== null}
                    active={overIndex === null ? typeof valueIndex === 'number' && valueIndex >= i : overIndex >= i}
                    selected={valueIndex === i}
                    onMouseEnter={() => setOverIndex(i)}
                    onClick={disabled ? undefined : () => onChange(i)}
                    children={String(start + i)}
                  />
                </EmotionometerUnitContainer>
              ))}
            </>
          )}
        </InputComponent>
      </EmotionometerScale>
      <EmotionometerLegend>
        {(labels || []).map((label, i) => (
          <EmotionometerLegendLabel key={i} children={label} />
        ))}
      </EmotionometerLegend>
    </>
  )
}

const emotionometerThemes: { [key in EmotionometerSectionType['theme']]: string } = {
  white: '#fffedf',
  blue: '#18A0FB',
  green: '#4EBE40',
  red: '#ED1C24',
  purple: '#9C6AEE',
  yellow: '#FFB800',
}

const getUnitColor = (theme: EmotionometerSectionType['theme'], index: number, total: number) =>
  mix(0.2 + (index / total) * 0.8, emotionometerThemes[theme], '#ffffff')

const EmotionometerScale = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: -3px;
  margin-left: -3px;
`

const EmotionometerUnitContainer = styled.div`
  padding-right: 3px;
  padding-left: 3px;
  flex: 0 10%;
`

type EmotionometerUnitProps = {
  background: string
  interactive: boolean
  over: boolean
  active: boolean
  selected: boolean
  hovering: boolean
  themeColor: string
}

const EmotionometerUnit = styled.div<EmotionometerUnitProps>`
  ${fontRegular}
  padding: 9px 15px;
  border-radius: 45px;
  background-color: ${(p) => p.background};
  color: #011a46;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.03em;
  box-shadow:
    0 -2px 3px 0 hsla(0, 0%, 100%, 0.45),
    0 1px 3px 0 rgba(0, 0, 0, 0.15),
    inset 0 3px 9px 0 hsla(0, 0%, 100%, 0.8),
    inset 0 -1px 12px 0 #9aa1c1,
    inset 0 3px 12px 0 hsla(0, 0%, 100%, 0.94);
  ${(p) =>
    p.interactive
      ? css`
          /* user-select: none; */
          cursor: pointer;
          transition: all 0.15s linear;
          filter: ${p.active ? 'none' : 'grayscale(100%)'};
          ${p.hovering && !p.active ? 'box-shadow: none;' : ''}
          box-shadow: 0 -2px 3px 0 hsla(0, 0%, 100%, 0.45), 0 1px 3px 1px ${p.selected
            ? p.themeColor
            : 'rgba(0, 0, 0, 0.15)'},
            inset 0 3px 9px 0 hsla(0, 0%, 100%, 0.8), inset 0 -1px 12px 0 #9aa1c1, inset 0 3px 12px 0 hsla(0, 0%, 100%, 0.94);
          &:hover {
            filter: none;
          }
          &:active {
            box-shadow:
              0 -2px 3px 0 ${p.selected ? p.background : 'hsla(0, 0%, 100%, 0.45)'},
              0 1px 3px 0 rgba(0, 0, 0, 0.15),
              inset 0 3px 9px 0 hsla(0, 0%, 0%, 0.5),
              inset 0 -1px 12px 0 #9aa1c1,
              inset 0 3px 12px 0 hsla(0, 0%, 100%, 0.94);
          }
        `
      : css`
          pointer-events: none;
          /* user-select: none; */
          cursor: pointer;
          transition: all 0.15s linear;
          filter: ${p.active ? 'none' : 'grayscale(100%)'};
          ${p.hovering && !p.active ? 'box-shadow: none;' : ''}
          box-shadow: 0 -2px 3px 0 hsla(0, 0%, 100%, 0.45), 0 1px 3px 1px ${p.selected
            ? p.themeColor
            : 'rgba(0, 0, 0, 0.15)'},
            inset 0 3px 9px 0 hsla(0, 0%, 100%, 0.8), inset 0 -1px 12px 0 #9aa1c1, inset 0 3px 12px 0 hsla(0, 0%, 100%, 0.94);
          &:active {
            box-shadow:
              0 -2px 3px 0 ${p.selected ? p.background : 'hsla(0, 0%, 100%, 0.45)'},
              0 1px 3px 0 rgba(0, 0, 0, 0.15),
              inset 0 3px 9px 0 hsla(0, 0%, 0%, 0.5),
              inset 0 -1px 12px 0 #9aa1c1,
              inset 0 3px 12px 0 hsla(0, 0%, 100%, 0.94);
          }
        `}
`

const EmotionometerLegend = styled.div`
  display: flex;
  padding-top: 12px;
  padding-right: 8px;
  padding-left: 8px;
  justify-content: space-between;
`

const EmotionometerLegendLabel = styled.div`
  ${fontRegular}
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`
